import React from "react";

import { useState, useEffect, useRef } from "react";

import '../Home/Servicios/servicio.css';
import '../global.css';

import {ReactComponent as Arc} from '../assets/imgs/imgs-servicio/arc1.svg';
import {ReactComponent as BgArc} from '../assets/imgs/imgs-servicio/bgArc.svg';
import {ReactComponent as Line} from '../assets/imgs/imgs-servicio/line.svg';

import CenterImg from '../assets/imgs/imgs-servicio/centerImg.webp';
import center2 from '../assets/imgs/imgs-servicio/center2.webp';
import center3 from '../assets/imgs/imgs-servicio/center3.webp';
import center4 from '../assets/imgs/imgs-servicio/center4.webp';



import Bg1 from '../assets/imgs/imgs-servicio/bg1.webp';
import Bg2 from '../assets/imgs/imgs-servicio/bg2.webp';
import Bg3 from '../assets/imgs/imgs-servicio/bg3.webp';
import Bg4 from '../assets/imgs/imgs-servicio/bg4.webp';



function Wheel(props) {


    const [turn, setTurn] = useState(1);


    useEffect(() => {
        switch(props.turno) {
            case 1:
                setTurn(1);
                break;
            case 2:
                setTurn(2);
                break;
            case 3:
                setTurn(3);
                break;
            case 4:
                setTurn(4);
                break;
        }
        
    }, [props.turno])



    return (

    <>  

    <div className="bgContainerMobile">

        <div className="backgroundCircleMobile"></div>

        <div id="wheel" className="servicioLeftCol mont400" >
            
            <div className="bgArcWrapper" style={
                {display: 'flex', alignItems: 'center', justifyContent: 'center',
                paddingLeft: '0.2vw'}}>

                <img className="centerImg" src={CenterImg} style={turn == 1 ? {opacity: 1} : {opacity: 0}} ></img>
                <img className="centerImg" src={center2} style={turn == 2 ? {opacity: 1} : {opacity: 0}}></img>
                <img className="centerImg" src={center3} style={turn == 3 ? {opacity: 1} : {opacity: 0}}></img>
                <img className="centerImg" src={center4} style={turn == 4 ? {opacity: 1} : {opacity: 0}}></img>
            </div>

            {/*turn.includes(1) ? ...*/}
            
            <div className="bgArcWrapper" style={turn == 1 ? {opacity: 1} : {opacity: 0}}>
                <img className="bgArcWrapperChild" src={Bg1}></img>
            </div>
            <div className="bgArcWrapper" style={turn == 2 ? {opacity: 1} : {opacity: 0}}>
                <img className="bgArcWrapperChild" src={Bg2}></img>
            </div>
            <div className="bgArcWrapper" style={turn == 3 ? {opacity: 1} : {opacity: 0}}>
                <img className="bgArcWrapperChild" src={Bg3}></img>
            </div>
            <div className="bgArcWrapper" style={turn == 4 ? {opacity: 1} : {opacity: 0}}>
                <img className="bgArcWrapperChild" src={Bg4}></img>
            </div>
        


            <div className="innerWheelWrapper montBook"> 


            <div className="servicioRow1">
                <div className={props.turno == 1 ? 'numberCircle1 selectedNumber' : 'numberCircleGeneric unSelectedNumber'} >
                    1
                </div>
            </div>
                
            <div className="numberRow2">

                <div className={`genericCircleLeft ${props.turno == 4 ? 'numberCircle1 selectedNumber' : 'numberCircleGeneric unSelectedNumber'}`}>
                    4
                </div>
                <div className={`${props.turno !== 2 ? 'genericCircleRight ' : 'topNumberActive2'} ${props.turno == 2 ? 'numberCircle2 selectedNumber' : 'numberCircleGeneric unSelectedNumber'}`} >
                    2
                </div>

            </div>

            <div className="numberRow3">
                <div className= {`${props.turno == 3 ? 'numberCircle1 selectedNumber' : 'numberCircleGeneric unSelectedNumber'} num3Mobile`} >
                    3
                </div>
            </div>

            </div>

        </div>


    </div>
    </>

    )

}



export default Wheel;