import React, { useEffect, useState, useRef } from "react";

import '../Planes/planes.css';
import {v4 as uuidv4} from 'uuid';
import { ButtonNB } from "./ButtonNB";

function PlanDescription (props) {

   const [id, setId] = useState(uuidv4());
   const numPlan = useRef(props.info.plan);
   const [act, setAct] = useState(props.active);

    const {adjust} = props;
   
    const selected = props.info;

    useEffect(() => {
        if (numPlan.current == 0) {
            const tg = document.getElementById(id);
            if(tg){
       
            adjust(tg.offsetWidth, tg.offsetHeight);
            }
        }
    }, [])
    
    if (props.active == numPlan.current) {
        const tg = document.getElementById(id);
        if(tg){

        adjust(tg.offsetWidth, tg.offsetHeight);
        }
   }
   



    return (
        <>
    <div id={id} className='planAnual' style={props.active != numPlan.current ? {opacity: 0} : {opacity: 1}}>

    <div className='planTextTitle'>
        {selected.title}
    </div>

    <div className='planTextBullets'>
    <div className='planBulletsItems'>
        {selected.list}
    </div>
    </div>

    <div className='planTextDescription'>
        {selected.text}
    </div>

    <div className='planTextBottomRow'>

    <ButtonNB text={'Entrar en contacto'} link={'/contacto'} disable={props.active != numPlan.current ? true : false}></ButtonNB>

    <a className='downloadLink'>
        Descargar planificación
    </a>

    </div>
    </div>
        </>
    )
}

export default PlanDescription;