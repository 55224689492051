import React from 'react'
import { Link } from 'react-router-dom'

import '../global.css';
import './contactobutton.css';

export const ContactoButton = (props) => {

  return (
    <>
        <Link to={props.link}>
            <div className={`${props.change ? 'change' : 'notchange'} mont400`}>
                {props.text}
            </div>
        </Link>
    </>
  )
}
