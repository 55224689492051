import React from "react";

import './cuida.css';

import {ReactComponent as BiosCuida} from '../../assets/imgs/cuida/biosNameCuida.svg';
import CuidaPic from '../../assets/imgs/cuida/cuida.webp';

import { ButtonNB } from "../../Components/ButtonNB";

function Cuida() {
    return (
        <>
        <div className="cuidaOuterWrapper">
            <div className="cuidaLeftCol">
                <BiosCuida className="biosCuida"></BiosCuida>
                <div className="cuidaText montLight">
                Cuida la salud a través del conocimiento. Medimos lo importante para prevenir y mejorar la calidad de vida
                </div>
                <ButtonNB text={'Conocé más'} link={"/planes"} hash={"top"}></ButtonNB>
            </div>
            
            <img className="imgcuida" src={CuidaPic}></img>
            

        </div>
        </>
    )
}

export default Cuida;