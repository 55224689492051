import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useState, useEffect, useRef } from "react";

import axios from "axios";

function OneTimePay(props) {

   
    const [orderID, setOrderID] = useState("");
    const [show, setShow] = useState(props.enable);

    const [logData, setLogData] = useState(false);

    const finalizeOrder = useRef(false);

    const CLIENT_ID = process.env.REACT_APP_PP_CLIENT_ID;
    //const CLIENT_SECRET = process.env.REACT_APP_PP_SECRET;

    const formData = props.formData;

    const orderIdRef = useRef("");

    useEffect(() => {
        setShow(props.enable);
    }, [props.enable])


    const createOrder = async () => {
      try {
            
          const response = await fetch("https://api.biostracker.com/paypal/create-order", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  name: props.plan,
              }),
          });
  
          if (!response.ok) {
              throw new Error('Failed to create order');
          }
  
          const orderID = await response.text();
          orderIdRef.current = orderID;
          setOrderID(orderID);
          console.log(orderID);
          console.log("Order ID:", orderID);
  
          return orderID;
      } catch (error) {
          console.error('Error creating order:', error);
          throw error;
      }
  };
  
  
 
        

        
      const onApprove = () => {
        /*
        return actions.order.capture().then(function(details) {
          console.log('Transaction approved:', details);
          // Handle successful transaction
        });
        */
       
        let data = JSON.stringify({
            "orderID": orderIdRef.current,
            "email": formData.email,
            "phone": formData.phone,
            "name": formData.name,
            "lastname": formData.lastname,
            "address": formData.address,
            "floor": formData.floor,
            "city": formData.city,
            "country": formData.country,
            "zipcode": formData.zipcode,
            "isPayed": true,
            "isSubscription": false,
            "plan": props.plan,
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.biostracker.com/sheets/logorder',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            //callbackOrderCompleted(true, orderID);
            props.setPayed(true, orderIdRef.current);
            //
            
            //
            console.log(JSON.stringify(response.data));

          })
          .catch((error) => {
            console.log('IM the errorrr, on approve at onetimepay.js')
            console.log(error);
          });
          
         

      };
    


    return (
        <>

        <div className="centering-container">

            <div className="max-width-container">

                        
                <div className="pay-block-container">

                    

                    <PayPalScriptProvider options={{"client-id": CLIENT_ID}}>
                        
                        <PayPalButtons
                                style={{ layout: "vertical"}}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                enableFunding={{credit: true, card: true, paylater: true}}
                                disabled={!show}
                        />
                        
                    </PayPalScriptProvider>

                </div>

                
        
            </div>

        </div>


        </>
    )
}

export default OneTimePay;