import React, { useEffect } from "react";
import Recomendacion from "../../Components/Recomendacion";

import './carouselrec.css';

import magnesio from '../../assets/imgs/recomendaciones/magnesio.webp';
import bike from '../../assets/imgs/recomendaciones/bike.webp';
import hiker from '../../assets/imgs/recomendaciones/hiker.webp';
import rice from '../../assets/imgs/recomendaciones/rice.webp';
import sleeping from '../../assets/imgs/recomendaciones/sleeping.webp';
import yoga from '../../assets/imgs/recomendaciones/yoga.webp';


import { useRef } from "react";

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { Link } from "react-router-dom";



function CarouselRec() {

    


    const context = require.context('../../content/posts', false, /\.json$/);


    const jsonDataWithFilename = context.keys().map((key) => {
   
      const filename = key.split('/').pop().replace(/\.json$/, '');

     
      const jsonData = context(key);

  
      return {
        filename,
        data: jsonData,
      };
    });

  
    

    const splideRef = useRef(null);

    const title = <div>Aporte de magnesio <br/> en la alimentacion</div>;
    const subtitle =  'Las gafas deportivas Oakley son la elección perfecta para los ciclistas más exigentes. Estas gafas, diseñadas especialmente para la.';

    const componentsArray = Array.from({ length: 6 }, (_, index) => (
        <SplideSlide>
        <Recomendacion
            img={magnesio}
            title={title}
            subtitle={subtitle}
        >
        </Recomendacion>            
    </SplideSlide>
      ));



    const subtitlePlaceholder = 'Las gafas deportivas Oakley son la elección perfecta para los ciclistas más exigentes. Estas gafas, diseñadas especialmente para la.';
    const titlePlaceholder = 'Una dieta equilibrada';
    
    const dataObject = [
        {
            img: magnesio,
            title: 'Aporte de Magnesio en la alimentación',
            subtitle: subtitlePlaceholder,
        },
        {
            img: sleeping,
            title: 'El sueño, el cortisol y el control de peso.',
            subtitle: subtitlePlaceholder,
        },
        {
            img: hiker,
            title: 'Los biomarcadores claves en la actividad física.',
            subtitle: subtitlePlaceholder,
        },
        {
            img: yoga,
            title: titlePlaceholder,
            subtitle: subtitlePlaceholder,
        },
        {
            img: bike,
            title: titlePlaceholder,
            subtitle: subtitlePlaceholder,
        },
        {
            img: rice,
            title: titlePlaceholder,
            subtitle: subtitlePlaceholder,
        }
    ]

    
    const parseImagePath = (path) => {
        const pathSegments = path.split('/');
        const filename = pathSegments[pathSegments.length - 1];
        return filename;

    }

    const checkForMiniatureImage = (data) => {
        if (data.hasOwnProperty('miniatureImage') && data.miniatureImage != '') {
            return parseImagePath(data.miniatureImage);
        }
        if (data.hasOwnProperty('image')) {
            return parseImagePath(data.image);
        } else {
            return '';
        }
    }

    

    return(
    <>
    <div id="slider" className="carouselWrapper">
        
        <div className="recomendacionesTituloExterior montLight">
            Algunas <br/> recomendaciones
        </div>

        <Splide
            options={{
                drag: 'free',
                arrows: false,
                pagination: false,
                direction: 'ltr',
                width: '100%',
                autoWidth: true,
                autoHeight: true,                
                gap: '0.5vw'                
            }}

            ref={splideRef}
        > 
     
        
        {/*dataObject.map(function(data) {
            return (
                <SplideSlide>
                    <Recomendacion
                        img={data.img}
                        subtitle={data.subtitle}    
                        title={data.title}
                        
                    >

                    </Recomendacion>
                </SplideSlide>
            )
        })*/}

        {
            jsonDataWithFilename.map(function(data) {
                return (
                    <SplideSlide>
                        <Link to={`/article/${data.filename}`} onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <Recomendacion
                            img={require('../../content/posts/media/' + checkForMiniatureImage(data.data))}
                            title={data.data.title}
                            subtitle={data.data.body}
                        >
                        </Recomendacion>
                        </Link>
                    </SplideSlide>
                )
            })
        }
        
        

        </Splide>

    </div>
    </>
    )
}


export default CarouselRec;