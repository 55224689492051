import React from "react";

import './arrowdown.css';

import {ReactComponent as InnerArrow} from '../assets/arrowDownIcon.svg';

import { HashLink } from "react-router-hash-link";


function ArrowDown (props) {



    const hoverEffect = (moveDown) => {
        const element = document.querySelector('.innerArrows');
        if (moveDown) {
            
            element.style.marginTop = '1vw';
        } else {
            
            element.style.marginTop = '0vw';
        }

    }

    return (
        <>
        <HashLink smooth to={"/#crecemos"}>
        <div className="arrowDownPosition">
            <div className="arrowDownContainer" onMouseOver={() => hoverEffect(true)} onMouseLeave={() => hoverEffect(false)}>
                <div className="innerArrows" >
                    <InnerArrow></InnerArrow>
                    <InnerArrow></InnerArrow>
                </div>
            </div>
        </div>
        </HashLink>
        </>
    )

}



export default ArrowDown;