import React from "react";

import './pilares.css';

import GreenLogo from '../../assets/imgs/pilares/greenLogo.webp';
import {ReactComponent as GreenLogoBg} from '../../assets/imgs/pilares/greenLogoBg.svg';
import Flower from '../../assets/imgs/pilares/flowerPilares.webp';
import Yogurt from '../../assets/imgs/pilares/yogurt.webp';
import BLogo from '../../assets/imgs/pilares/bioslogoPilares.webp';

import {ReactComponent as BiosLogoMobile} from '../../assets/imgs/pilares/biosPilaresMobile.svg';

import {ButtonNB} from '../../Components/ButtonNB';

import {ReactComponent as FireLogo} from '../../assets/imgs/pilares/fire.svg';

function Pilares () {



    return (
        <>
        <div className="pilaresOuterWrapper">

            <div className="columnContainer1">

                <div className="rowContainer1">
                    <div className="greenLogoContainer">
                        <GreenLogoBg style={{position: 'absolute', width: 'clamp(1px, 12.7vw, 183px)', height: 'clamp(1px, 12.7vw, 183px)'}}/>
                        <img className="greenLogo" src={GreenLogo}></img>
                    </div>
                    
                    <div className="bLogoMobile" src={BiosLogoMobile}>
                        <BiosLogoMobile></BiosLogoMobile>
                    </div>
                    <div className="greenLogoContainerMobile"></div>
                    <img className="flower" src={Flower}></img>
                </div>

                <div className="rowContainer2">
                    
                    <img className="yogurt" src={Yogurt}></img>
                    <div className="subrowContainer1">
                        <div className="subrowColumn1">
                            <div className="fireLogoStyles">
                                <FireLogo style={{marginBottom: '2px'}}></FireLogo>
                            </div>
                        </div>
                        <div className="subrowColumn2">
                            <div className="subRowColumn2Title">
                                Tostadas con iogurth y almendras
                            </div>
                            <div className="subRowColumn2Sub">
                                123 kcal
                            </div>
                        </div>
                        
                    </div>

                </div>

            </div>

            <div className="columnContainer2">
                <div style={{display: 'flex',
                flexDirection: 'column',
                gap: 'clamp(1px, 3.30vw, 47.65px',
                
                }}>
                    <img  className="blogo" src={BLogo}></img>
                    <div className="pilaresText mont400">
                        Uno de los pilares fundamentales de Biostracker se basa en mejorar nuestra nutrición, sugiriendo alimentos específicos en base a las necesidades que dictan los estudios de sangre.
                    </div>
                </div>
                    <div className="pilaresSaberMasMobile" style={{marginTop: 'clamp(1px, 2.36vw, 34px'}}>
                        <ButtonNB text={'Saber más'} link={'quienes-somos'}></ButtonNB>
                    </div>
                </div>

        </div>
        </>
    )
}

export default Pilares;