import React from "react";

import '../Home/Recomendaciones/carouselrec.css';
import './recsvertical.css';




import Recomendacion from "./Recomendacion";

import { Link } from "react-router-dom";

import { useState, useEffect } from "react";

function RecsVertical () {

  //TODO: Separar en su componente a las recomendaciones para hacer dinamico la cantidad x linea on window resize.

    const context = require.context('../content/posts', false, /\.json$/);


    const jsonDataWithFilename = context.keys().map((key) => {

      const filename = key.split('/').pop().replace(/\.json$/, '');

      const jsonData = context(key);

      return {
        filename,
        data: jsonData,
      };
    });
    
    
    

      const parseImagePath = (path) => {
        const pathSegments = path.split('/');
        const filename = pathSegments[pathSegments.length - 1];
        return filename;

    }

    const checkForMiniatureImage = (data) => {
        if (data.hasOwnProperty('miniatureImage' && data.miniatureImage != '')) {
            return parseImagePath(data.miniatureImage);
        }
        if (data.hasOwnProperty('image')) {
            return parseImagePath(data.image);
        } else {
            return '';
        }
    }

    const renderRow = (rowData, startIndex) => {
      return (
        <div className="recsRow" key={`row-${startIndex}`}>
          {rowData.map((data, index) => (
            <Link key={`link-${startIndex + index}`} to={`/article/${data.filename}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <div className="gapsBetweenRows">
                <Recomendacion
                  img={require('../content/posts/media/' + checkForMiniatureImage(data.data))}
                  title={data.data.title}
                  subtitle={data.data.body}
                />
              </div>
            </Link>
          ))}
        </div>
      );
    };

    const [perRow, setPerRow] = useState(3);

    useEffect(() => {
      checkHowManyRowsOnResize();

      window.addEventListener('resize', checkHowManyRowsOnResize);

    }, [])

    const checkHowManyRowsOnResize = () => {
      if (window.innerWidth < 479) {
        setPerRow(2);
      } else {
        setPerRow(3);
      }
    }

    return (
        <>

    <div className="recVerticalOuterWrapper">


      <div className="recsVerticalContainer">

          <div className="rowGaps">

      
        <div className="recomendacionesTituloExterior montLight hide" style={{marginBottom: 'clamp(1px, 2.5vw, 36px'}}>
                Algunas <br/> recomendaciones
        </div>
      
        

           {
            <>


            {jsonDataWithFilename.reduce((rows, data, index) => {
              const rowIndex = Math.floor(index / perRow);
      
              if (!rows[rowIndex]) {
                rows[rowIndex] = [];
              }
      
              rows[rowIndex].push(data);
      
              return rows;
            }, []).map((rowData, rowIndex) => renderRow(rowData, rowIndex * perRow))}
          </>
           }
        

        

         
          </div>

      </div>

      

    </div>
        </>
    )
}


export default RecsVertical;