import React from 'react';


import './aboutnav.css';
import { ContactoButton } from "./ContactoButton";

import BiosLogo from '../assets/imgs/biosWhite.webp';
import { Link } from "react-router-dom";

export const PlanesNavbar = () => {
  return (
    <>
    <div className="aboutNavContainer">
        <Link to={'/'}>
        <div className="biosAboutLogo">
            <img  className="biosLogoAbout" src={BiosLogo}></img>
        </div>
        </Link>
        {/*adjust fonts*/}
        <div className="mainButtonsContainer mont400" style={{color: 'white'}}>
            <Link to={'/quienes-somos'}>Sobre Nosotros</Link>
            <Link to={'/quienes-somos'}>Cómo Funciona</Link>
            <Link to={'/planes'}>Nuestros Planes</Link>
        </div>
                <ContactoButton text={'Contacto'} link={'/contacto'} change={true}></ContactoButton>
        </div>
    </>
  )
}
