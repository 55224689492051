import React from "react";

import {ReactComponent as BlueBubble} from '../assets/blueBubble.svg';
import '../Home/home.css';
import { useEffect, useState } from "react";

function Bubble (props) {

    const [vis, setVis] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setVis(true);
        }, props.delay);
    }, [])


    return (
        <>
        <div className={props.class} style={vis ? {opacity: 1} : {opacity: 0}}>            
            <BlueBubble/>
        </div>
        </>
    )
}



export default Bubble;