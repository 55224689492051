import React from "react";

import { useState, useEffect } from "react";

import './checkout.css';

import { NavBar } from "../Home/NavBar/NavBar";

import { ButtonNB } from "../Components/ButtonNB";

import '../Components/buttonnb.css';

import { Navigate, useNavigate } from "react-router-dom";

import {ReactComponent as Logo} from '../assets/imgs/checkout/6vrytexlfu4lt8vt5s0.svg';

import {ReactComponent as ArrowLeft} from '../assets/imgs/checkout/arrowleft.svg';

function OrderSelection () {

    const navigate = useNavigate();
    const [order, setOrder] = useState("anual");
    const [confirmOrder, setConfirmOrder] = useState(false);
    const [paymentType, setPaymentType] = useState("one-time");
    
    

    const selectOrder = (selectedOrder) => {

        if (order != selectedOrder) {
            setOrder(selectedOrder);
        } else {
            setOrder("");
        }

    } 

    const orderStyle = (orderNumber) => {

        if (orderNumber != order) {
            return {borderColor: 'black', opacity: 0.5}
        } else {
            return {borderColor: '#58708E', opacity: 1}
        }

    }

    const paymentStyle = (type) => {
        if (type != paymentType) {
            return {borderColor: 'black', opacity: 0.5}
        } else {
            return {borderColor: '#58708E', opacity: 1}
        }
    }

    const selectPaymentType = (type) => {
        if (paymentType != type) {
            setPaymentType(type);
        } else {
            setPaymentType("");
        }
    }


    const confirmSelectedOrder = () => {

        if (order != "") {
            setConfirmOrder(true);
        } else {
            alert("Por favor seleccione un servicio.")
        }

    }

    const goToCheckout = () => {
        if (order != "" && paymentType != "") {
            console.log(order);
            console.log(paymentType);
            navigate(`/checkout/${order}/${paymentType}`);
        } else {
            alert("Por favor seleccione un tipo de pago.")
        }
    }

    return (
        <>

        <NavBar></NavBar>
        
            <div className="centering-container" style={{paddingBottom: '100px'}}>
                <h3 className="mont400" style={{marginBottom: '30px'}}>{!confirmOrder ? 'Seleccione un plan para continuar' : 'Seleccione su forma de pago preferida'}</h3>
            <div className="max-width-container">
                {!confirmOrder ? 
                <div className="order-selection-wrapper">
                    <div className="order-selection-container">
                        
                        <div className="order-option-container mont400" onClick={() => selectOrder("semestral")} style={orderStyle("semestral")}>
                            <div style={{display: 'flex', gap: '10px', alignItems: 'center', flexDirection: 'column'}}>
                            <Logo></Logo>
                            <div className="">Plan semestral</div>
                            </div>
                            {window.innerWidth > 800 ? 
                            <>
                            <div>
                                <ul>
                                    <li>2 extracciones</li>
                                    <li>Plan de acción personalizado</li>
                                    <li>Contenido semanal digital</li>
                                </ul>
                            </div>
                            { window.innerWidth > 91440 ? 
                            <div className="order-text" style={{maxWidth: '90%'}}>
                            Podrás descrubrir con la primer extracción de sangre lo que tu cuerpo necesita. Luego junto con el plan de acción recomendado y el acompañamiento, podremos volver a evaluar los biomarcadores para ver los avances. Además, recibirás contenido digital semanalmente para además nutrir el conocimiento.
                            </div>
                            : null}   
                            </>
                            : null}
                           
                        </div>
                        
                        
                        <div className="order-option-container mont400" onClick={() => selectOrder("anual")} style={orderStyle("anual")}>
                        <div className="recomended-label mont400">Recomendado</div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'center', flexDirection: 'column'}}>
                            <Logo></Logo>
                            <div className="">Plan anual</div>
                            </div>
                            {window.innerWidth > 800 ?
                            <>
                            <div>
                                <ul>
                                    <li>4 extracciones</li>
                                    <li>Plan de acción personalizado</li>
                                    <li>Contenido semanal digital</li>
                                </ul>
                            </div>
                            {window.innerWidth > 91440 ?
                            <div className='order-text' style={{maxWidth: '90%'}}>
                            Con nuestro servicio de Biostracker, obtiendrás una experiencia completa para potenciar tu salud. Con las extracciones trimestrales de sangre desde la comodidad de tu casa, nuestro equipo de profesionales analiza tus biomarcadores y te brinda información detallada sobre tu estado de salud.  
                            </div>
                            : null}
                            </>
                            : null}
                        </div> 
                        
                    </div>
                    <div className="buttonBlue" onClick={() => confirmSelectedOrder()} style={{alignSelf: "center", cursor: "pointer"}}>Continuar</div>
                </div>
                : 
                <div className="order-selection-wrapper" style={{marginTop: '-20px'}}>
                    <div onClick={() => setConfirmOrder(false)} style={{alignSelf: "flex-start", maxWidth: '30px', cursor: 'pointer'}}>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                            <div><ArrowLeft></ArrowLeft></div>
                            <div>Volver</div>
                        </div>
                    </div>
                <div className="order-selection-container">
                    
                    <div className="order-option-container mont400" onClick={() => selectPaymentType("one-time")} style={paymentStyle("one-time")}>
                    
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
                            <Logo></Logo>
                            <div>Pago Unico</div>
                            {/*<div style={{display: 'flex', gap: '5px', alignItems: 'center', color: 'green'}}><div style={{fontSize: '30px'}}>20%</div> OFF</div>*/}
                            <div>
                                <ul>
                                    <li>20% de descuento</li>
                                    <li>Pago por unica vez</li>
                                    <li>Opciones de financiamiento</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="order-option-container mont400" onClick={() => selectPaymentType("subscription")} style={paymentStyle("subscription")}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
                            <Logo></Logo>
                            <div>Pago Mensual</div>
                            <div>
                                <ul>
                                    <li>{order === 'anual' ? '12' : '6'} pagos mensuales</li>
                                    <li style={{opacity: 0}}></li>
                                    <li style={{opacity: 0}}></li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                    
                </div>
                <div className="buttonBlue" onClick={() => goToCheckout()} style={{alignSelf: "center", cursor: "pointer"}}>Continuar</div>
                
            </div>
                }
            </div>
        </div>
     

        </>
    )
}

export default OrderSelection;