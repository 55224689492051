import { NavBar } from './NavBar/NavBar';
import './home.css';
import Avatar from '../assets/avatar.webp';
import '../global.css';
import {ButtonNB} from '../Components/ButtonNB';
import { Cellphones } from '../Components/Cellphones';
import Servicio from './Servicios/Servicio';

import almonds from '../assets/imgs/almonds.webp';
import cells from '../assets/imgs/cells.webp';
import dna from '../assets/imgs/dna.webp';
import fruits from '../assets/imgs/fruits.webp';
import leaf from '../assets/imgs/leaf.webp';
import salad from '../assets/imgs/salad.webp';
import waking_up from '../assets/imgs/waking_up.webp';
import woman_dancing from '../assets/imgs/woman_dancing.webp';
import woman_drinking from '../assets/imgs/woman_drinking.webp';
import Membresia from './Membresia/Membresia';
import Cuida from './Cuida/Cuida';
import Footer from './Footer/Footer';
import CarouselRec from './Recomendaciones/CarouselRec';
import ArrowDown from '../Components/ArrowDown';


import Pilares from './Pilares/Pilares';


import Dna from '../assets/imgs/footer/dnacut.webp';
import Leaf from '../assets/imgs/footer/leafcut.webp';
import PinkCells from '../assets/imgs/footer/pinkcells.webp';


import { useState, useEffect } from 'react';
import MobileMenu from '../Menu/MobileMenu';

import {ReactComponent as BlueBubble} from '../assets/blueBubble.svg';

import { Parallax } from 'react-parallax';

import WhatsAppLink from '../Components/WhatsAppLink';

import Bubble from '../Components/Bubble';

import Loading from '../Components/Loading';



function Home() {

  const imgList = [
    {
      src: almonds,
      className: 'almonds'
    },
    {
      src: cells,
      className: 'cells'
    },
    {
      src: dna,
      className: 'dna'
    },
    {
      src: fruits,
      className: 'fruits'
    },
    {
      src: leaf,
      className: 'leaf'
    },
    {
      src: salad,
      className: 'salad'
    },
    {
      src: waking_up,
      className: 'waking_up'
    },
    {
      src: woman_dancing,
      className: 'woman_dancing'
    },
    {
      src: woman_drinking,
      className: 'woman_drinking'
    },
];

  const [open, setOpen] = useState(false);

  const openMenu = () => {
    if(open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }


  const [loading, setLoading] = useState(true);


  useEffect(() => {

    const hasLoadedBefore = sessionStorage.getItem('hasLoadedBefore');

    console.log('hasloaded');
    console.log(hasLoadedBefore);



    const handleLoad = () => {
      
      setLoading(false);
      sessionStorage.setItem('hasLoadedBefore', 'true');
    };
  
    
    if (document.readyState === 'complete') {
      setLoading(false);
    } else {
      
      window.addEventListener('load', handleLoad);
    }
  
    
    return () => {
      window.removeEventListener('load', handleLoad);
    };

    
  }, []);
  



  return (
    <>

    
    
    <Loading load={loading}></Loading>

    <div style={!loading ? {opacity: 1, transition: 'all ease 1s'} : {opacity: 0}}>
    
    <WhatsAppLink></WhatsAppLink>

      <MobileMenu menu={open} onClick={openMenu}></MobileMenu>
    
    <NavBar change={false} onClick={openMenu}></NavBar>

    

    <div className="skyContainer">  


      <Bubble class={'blueBubble3 hideDesktop'} delay={1400}/>
      
      <div className='arrowDownWideWrapper'>
        <div className='arrowPosContainer' style={{marginBottom: 'clamp(1px, 6.59vw, 95px)'}}>
          <ArrowDown tag={document.querySelector(".crecemosText")}></ArrowDown>
        </div>
      </div>
     <div className='mainTextMobileContainer'>
      <h1 className='mainText mont400'>No es solo cuestión de edad, es la elección de envejecer con salud</h1>
      
      <Bubble class={'blueBubble1 hideDesktop'} delay={1000}></Bubble>

      <Bubble class={'blueBubble2 hideDesktop'} delay={1200}></Bubble>

      </div>
      
        <div className='avatarMobileContainer'>
         
            <img className='avatar' src={Avatar}></img>
          
          </div>
        
    </div>
    <div className='crecemosWrapper'>
      <div id="crecemos" className='crecemosText  montThin'>Crecemos a lo largo del 25 % de nuestra vida y el resto del
      tiempo envejecemos. Nuestros hábitos inciden un 75% en 
      cómo la vivimos</div>
      

      <Bubble class={'blueBubble1 hide'} delay={1000}></Bubble>
      <Bubble class={'blueBubble2 hide'} delay={1200}></Bubble>
      <Bubble class={'blueBubble3 hide'} delay={1400}></Bubble>

      <div>
        {imgList.map((img)=>
        <img
          src={img.src}
          className={img.className}
          style={{position: 'absolute', overflow: 'hidden'}}
          alt=''>
        </img>)}
        </div>

      <div className='crecemosQuoteWrapper montLight'>
          <div className='crecemosQuoteContainer'>
              <>Cultivamos la salud con <br/>nutrientes, el combustible <br/>
              esencial para tu cuerpo y <br/>mente. La respuesta esta <br/>en nuestro ADN.</>
              <ButtonNB text={'Saber más'} link={'/quienes-somos'}></ButtonNB>
          </div>
        </div>
        <div className='crecemosQuoteWrapperTwo montLight'>
          <div className='crecemosQuoteContainer'>
            <>
            Aprendé con nosotros,<br/>
            como nutrir tu cuerpo <br/>
            para disfrutar de forma <br/>
            saludable el mañana
            </>
          </div>
        </div>
        
    </div>
    <div className='cellphoneHomeWrapper' style={{overflow: 'hidden'}}>
        <Cellphones></Cellphones>
    </div>
    <Servicio></Servicio>

    <Pilares></Pilares>

    <Membresia></Membresia>
    
    <Cuida></Cuida>
    
    <CarouselRec></CarouselRec>

    <Footer pic1={Leaf} pic2={PinkCells} pic3={Dna}></Footer>
    
    </div>
    </>
  );
  
}

export default Home;
