import React from "react";
import { useEffect, useState, useRef } from "react";

import './recomendacion.css';
import '../global.css';

import {ReactComponent as HoverArrow} from '../assets/imgs/recomendaciones/hoverArrow.svg';



function Recomendacion(props) {


    const hoverRef = useRef(null);
    
    const [hover, setHover] = useState(false);
    
    useEffect(() => {

       
        const hoverbox = hoverRef.current;

        hoverbox.addEventListener('mouseover', () => {
            setHover(true);
            
        })

        hoverbox.addEventListener('mouseleave', () => {
            setHover(false);
        })
    }, [])


    const hoverActive = {
        borderRadius: "412px",
        filter: 'contrast(1.25)',
        filter: 'brightness(1.25)'
        
    }

    const hoverArrowActive = {
        top: '300px',
        right: 0,
        opacity: 1
    }

    const subtitleActive = {
        opacity: 1
    }


    return (
        <>
        <div ref={hoverRef} className="recOuterWrapper mont400" >

            <div className="recWrapper">
                <div className="imgTitleContainer"> 
                    <div className="hoverArrow" style={hover ? hoverArrowActive : {}}>
                        <HoverArrow style={{/*backdropFilter: 'blur(10px)', borderRadius: '100%'*/}}></HoverArrow>
                    </div>{/*style={hover ? hoverActive : {}}*/}
                    <img className="recommendedPfp" src={props.img} style={window.innerWidth>478 && hover ? hoverActive : {} }></img>
                    <div className="recomendacionTitle montRegular">
                        {props.title}
                    </div>
                </div>
                <div className="recomendacionSubtitle" style={window.innerWidth>478 && hover ? subtitleActive : {opacity: 0}}>
                    {props.subtitle}
                </div>
            </div>

            <div className="rightLine"></div>

        </div>
        
        </>
    )
}


export default Recomendacion;