import React from "react";
import {PlanesNavbar} from '../Components/PlanesNavbar';

import { useParams } from 'react-router-dom';

import { useState } from "react";

import './article.css';

//import articleData from '../content/posts/test.json';
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { ReactComponent as ArrowBack } from '../assets/imgs/arrowBack.svg';

import CarouselRec from '../Home/Recomendaciones/CarouselRec';
import Footer from '../Home/Footer/Footer';

import MobileMenu from "../Menu/MobileMenu";

import { NavBar } from "../Home/NavBar/NavBar";

import Dna from '../assets/imgs/footer/dnacut.webp';
import Leaf from '../assets/imgs/footer/leafcut.webp';
import PinkCells from '../assets/imgs/footer/pinkcells.webp';

import { HashLink } from "react-router-hash-link";

function Article () {

    const {name, id} = useParams();

    //const articleName = name.replace(/\s+/g, '-');
    
    const navigate = useNavigate();
    

    const [article, setArticle] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [found, setFound] = useState(false);
    
    //const [images, setImages] = useState({});
    const [images, setImages] = useState({
        coverImage: "",
        centerImage: "",
        bottomImage: "",
      });

      const [open, setOpen] = useState(false);

      const openMenu = () => {
        if(open) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }
    
    //<h1>Articulo: {id}</h1>
    
    useEffect(() => {
        try {
        const articleData = require(`../content/posts/${name}.json`);
        setArticle(articleData);
        
        if(articleData !== null) {
            setFound(true);
        }

        
        } catch (error) {
           
        }
  
      }, [name]);

     
      useEffect(() => {
        try {
            
        if (article) {
        /*
        const imgs = {
            coverImage: "",
            centerImage: "",
            bottomImage: "",
        };
        
        if(article.hasOwnProperty('image')) {
            imgs.coverImage = article.image;
        }
        if(article.hasOwnProperty('centerImage')) {
            imgs.centerImage = article.centerImage;
        }
        if(article.hasOwnProperty('bottomImage')) {
            imgs.bottomImage = article.bottomImage;
        }
        */
        const imgs = {
            coverImage: article.hasOwnProperty('image') ? article.image : "",
            centerImage: article.hasOwnProperty('centerImage') ? article.centerImage : "",
            bottomImage: article.hasOwnProperty('bottomImage') ? article.bottomImage : "",
          };
        

        for (let key in imgs) {
            if (imgs[key] === "") {
                //delete imgs[key];
                imgs[key] = "coverDefault.png";
            } else {
                const pathSegments = imgs[key].split('/');
                const filename = pathSegments[pathSegments.length - 1];
                imgs[key] = filename;
            }
        }

        setImages(imgs);
        
        setLoading(false);
        

    }
        } catch(error) {
           
        }
      }, [article])
  
      

      const renderWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
      };




    if (found) {
        //
        return(
            <>

            <MobileMenu menu={open} onClick={openMenu}></MobileMenu>

            <div className="articleCoverContainer" >
                <div className="hideMobile" style={{zIndex: 999}}><PlanesNavbar /></div>
               <div className="hideDesktop"><NavBar change={true} onClick={openMenu}></NavBar></div>
                <img className="coverBackgroundImage" src={images.coverImage != "" ? require(`../content/posts/media/${images.coverImage}`) : require('../content/posts/media/coverDefault.png')}></img>
                <div className="coverTitleContainer">
                    <div className="goBackRowContainer">
                        <div className="gobackArrow">
                            <HashLink to={'/#slider'}>
                                <ArrowBack />
                            </HashLink>
                        </div>
                        <div className="gobackText">
                            Recomendaciones
                        </div>
                    </div>
                    <div className="coverTitle">
                        {article.title}
                    </div>
                </div>
            </div>

            <div className="articleBodyContainer">

                <div className="articleBodyText">
                    {renderWithLineBreaks(article.body)}
                </div>

                <div className="articleCenterImageContainer">
                   {article.hasOwnProperty('centerImage') ? <img className="articleCenterImage" src={images.centerImage != "" ? require(`../content/posts/media/${images.centerImage}`) : ""}></img> : "" }
                </div>

                <div className="articleBottomRow" style={article.switchOrder ? {flexDirection: 'row-reverse'} : {flexDirection: 'row'}}>
                    <div className="articleBottomTextContainer">
                        {article.hasOwnProperty('bottomText') ? <div className="articleBottomText">{renderWithLineBreaks(article.bottomText)}</div> : ''}
                    </div>
                    <div className="aritcleBottomImage">
                       {article.hasOwnProperty('bottomImage') ? <img src={images.bottomImage != "" ? require(`../content/posts/media/${images.bottomImage}`) : ''}></img> : ''  }
                    </div>
                </div>

            </div>

            <div className="outOfArticleContainer">
                <CarouselRec></CarouselRec>
                <Footer pic1={Leaf} pic2={PinkCells} pic3={Dna}></Footer>
            </div>
            </>
        )

    } else {

        return(
            <>
            <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <h1>Articulo no encontrado</h1>
                <h2>Volviendo al home...</h2>
                {/*
                    setTimeout(() => {
                        navigate('/');
                    }, 5000)
                */
                }
            </div>
            </>
        )
    }
}

export default Article;