import React from "react";

import { useEffect, useState, useRef } from "react";

import {v4 as uuidv4} from 'uuid';

import '../Home/Servicios/servicio.css';
import '../global.css';

import {ReactComponent as Line} from '../assets/imgs/imgs-servicio/line.svg';
import {ReactComponent as Cross} from '../assets/imgs/imgs-servicio/cross.svg';


function AccordeonRow(props) {

   
    
    const [idRow, setIdRow] = useState(uuidv4());
    const [buttonId, setButtonId] = useState(uuidv4());
    const [rowTextId, setRowTextId] = useState(uuidv4());

    const [rowTitle, setRowTitle] = useState(uuidv4());

    const [idTitle, setIdTitle] = useState(uuidv4());

    const [idPaso, setIdPaso] = useState(uuidv4());
    
    const [clicked, setClicked] = useState(false);
    const [height, setHeight] = useState('65.99px');
    const [hideText, setHideText] = useState(true);

    const [defHeight, setDefHeight] = useState(null);


/*
    useEffect(() => {

        if(props.isClicked) {
            const newHeight = (1.1 * (document.getElementById(idRow).offsetHeight + document.getElementById(rowTextId).offsetHeight)) + 'px';
            setClicked(true);
            setHeight(newHeight);
            setHideText(false);
        } else {
            setClicked(false);
            if (window.innerWidth > 900) {
                //setHeight('3.3vw')
                setHeight(document.getElementById(rowTitle).offsetHeight);
            } else {
                //setHeight('8.45vw')
                setHeight(document.getElementById(rowTitle).offsetHeight);
            }
            setHideText(true);
        }

    }, [props.isClicked, idRow, rowTextId])
*/
const [marginTopStyle, setMarginTopStyle]  = useState('18px');

useEffect(() => {

    if(props.isClicked) {
        
        const step = document.getElementById(idPaso);
        step.style.display = 'block';
        setTimeout(() => {
            step.style.opacity = 1;
        }, 200);

        const newHeight = (1.3 * (defHeight + document.getElementById(rowTextId).offsetHeight)) + 'px';

        const marginTopStyle = defHeight - document.getElementById(rowTitle).offsetHeight + 'px';
        setMarginTopStyle(marginTopStyle);
       

        setClicked(true);
        setHeight(newHeight);
        setHideText(false);
    } else {
        setClicked(false);
        setHeight(defHeight);
        setHideText(true);

        const step = document.getElementById(idPaso);
        step.style.opacity = 0;
        setTimeout(() => {
            step.style.display = 'none';
            
        }, 200);
    }

}, [props.isClicked, idRow, rowTextId])

    useEffect(() => {
        setDefHeight(document.getElementById(rowTitle).offsetHeight * 1.2);

        
    }, [])

 

  

    let rowStyle;
    if (window.innerWidth > 900) {
        rowStyle = props.isClicked ? {fontSize: 'clamp(1px, 2.08vw, 30px)'} : {fontSize: 'clamp(1px, 1.38vw, 20px)'};
    } else {
        rowStyle = props.isClicked ? {fontSize: 'clamp(1px, 4.34vw, 18px)'} : {fontSize: 'clamp(1px, 3.38vw, 14px)'};
    }

    let buttonStyle;
    if (window.innerWidth > 900) {
        buttonStyle = props.isClicked ? {width: 'clamp(1px, 5vw, 73px', height: 'auto'} : {width: 'clamp(1px, 3.54vw, 51px', height: 'auto'};
    } else {
        buttonStyle = {};
    }
    

    

    

    return (
<>
 
    <div id={idRow} className={'rowWrapper'} style={{height: height, marginTop: marginTopStyle}}>

  

        <div id={rowTitle} className="rowTitleWrapper">

            <div className="rowTitleContainer">
                
                <div id={idPaso} className="numPaso" >
                    {props.paso}
                </div>
                
                <div id={idTitle} className="rowTitle montBook" style={rowStyle}>
                    {props.title}
                </div>
            </div>
            <div onClick={props.onClick} id={buttonId} className="accordeonButton" style={buttonStyle}>
                <div className="accordeonIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 51 45" fill="none">
                    <line x1="18.8633" y1="24.6507" x2="31.4386" y2="24.6507" stroke="#58708E" />
                    <line x1="25.6504" y1="18.863" x2="25.6504" y2="31.4384" stroke="#58708E" style={props.isClicked ? {opacity: 0} : {opacity: 1}} />
                </svg>
                </div>
            </div>

        </div>
        <div id={rowTextId} className="rowTextContainer" style={hideText ? {opacity: 0} : {opacity: 1}}>
            <div className="rowText">
                {props.message}
            </div>
        </div>
    </div>
</>
    )

}

export default AccordeonRow;