import React from "react";
import AboutNavbar from "../Components/AboutNavbar";
import { ButtonNB } from "../Components/ButtonNB";

import './about.css';
import RecsVertical from "../Components/RecsVertical";
import Servicio from "../Home/Servicios/Servicio";
import Footer from "../Home/Footer/Footer";

import almonds from '../assets/imgs/footerabout/almonds-footer.webp';
import salad from '../assets/imgs/footerabout/salad-footer.webp';
import cells from '../assets/imgs/footerabout/cells-footer.webp';

import { useState } from "react";

import MobileMenu from "../Menu/MobileMenu";

import WhatsAppLink from "../Components/WhatsAppLink";


function About() {

  const styleAlm = {
    width: 'clamp(1px, 29.58vw, 390px)',
    height: 'clamp(1px, 29.58vw, 390px)',
    bottom: 0


  }

  const styleCells = {
    width: 'clamp(1px, 29.02vw, 390px)',
    height: 'clamp(1px, 29.02vw, 390px)',
    marginLeft: '20vw'
  }

  const styleSalad = {
    width: 'clamp(1px, 33.88vw, 425px',
    height: 'clamp(1px, 33.88vw, 425px',
    marginBottom: 'clamp(1px, 2.98vw, 43px)'
  }

  const [open, setOpen] = useState(false);

  const openMenu = () => {
    if(open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  return (
    <>

    <WhatsAppLink></WhatsAppLink>

  <MobileMenu menu={open} onClick={openMenu}></MobileMenu>
    <AboutNavbar change={false} menu={open} onClick={openMenu}></AboutNavbar>

    <div className="aboutMessageContainer">
      <div className="headerMessage">
        Queremos que <br/> nos conozcas un <br/> poco más
      </div>
      <div className="headerSubtitle mont400">
        Somos un equipo multidisciplinario compuesto por expertos en bioquímica, nutrición y deporte, que hemos unido nuestras habilidades para llevar a cabo estudios de salud personalizados. 
      </div>
      <div className="buttonContactWrapper">
        <ButtonNB link={'/contacto'} text={'Entrar en contacto'}></ButtonNB>
      </div>
    </div>

    {/*Recomendaciones vertical*/}

    <Servicio></Servicio>
    
    <RecsVertical></RecsVertical>

    

    <div style={{marginTop: '14vw'}}>

      <Footer 
      pic1={almonds} styleL={styleAlm} 
      pic2={cells} styleT={styleCells}
      pic3={salad} styleR={styleSalad}
      />

      
    
    </div>



    </>
  )
}

export default About;