import React from "react";

import './cellphones.css';
import '../global.css';

import biosName from '../assets/imgs/cellphones/biosTrackerName.webp';
import fireIcon from '../assets/imgs/cellphones/fireIcon.webp';
import fruitBasket from '../assets/imgs/cellphones/fruitBasket.webp';
import placeholder from '../assets/imgs/cellphones/placeholder.png';
import arrowIcon from '../assets/imgs/cellphones/arrowIcon.webp';

function RightCellphone() {


    return(
        <>
        <div id="rightBox" className="cellphoneBoxRight">
            <img src={biosName} className="biosName"/>
            <div className="rightCellphoneInnerBox">
                <div className="fireIconWrapper">
                    <div className="fireIconContainer">
                        <img src={fireIcon}></img>
                        <div className="fireText">Calorias totales</div>
                    </div>
                    <div className="caloriasBottomText">348 kcal</div>
                </div>
                <div className="fruitBasketWrapper">
                    <img className="fruitBasket" src={fruitBasket}/>
                </div>
                <div className="fruitStats">
                    <img className="fruitStatsImg" src={placeholder}></img>
                </div>
               <div className='verMasResultadosRight mont400'>
                    <div>
                        Ver más resultados
                    </div>
                    <img className='verMasArrow' src={arrowIcon}></img>
                </div>
            </div>
        </div>
        </>
    )

}

export default RightCellphone;