import React from "react";

import './aboutnav.css';
import '../Home/NavBar/navbar.css'
import { ContactoButton } from "./ContactoButton";

import BiosLogo from '../assets/BiosLogo.webp';
import { Link } from "react-router-dom";
import {ReactComponent as MenuButton} from '../assets/menuButton.svg';

function AboutNavbar(props) {
    return (
        <>
        <div className="aboutNavContainer">
            <Link to={'/'}>
            <div className="biosAboutLogo">
                <img  className="biosLogoAbout" src={BiosLogo}></img>
            </div>
            </Link>
            {/*adjust fonts*/}
            <div className="mainButtonsContainer mont400" >
                <Link to={'/quienes-somos'}>Sobre nosotros</Link>
                <Link to={'/quienes-somos'}>Cómo funciona</Link>
                <Link to={'/planes'}>Nuestros planes</Link>
            </div>
            <div className="hide">
                <ContactoButton text={'Contacto'} link={'/contacto'} change={0}></ContactoButton>
                </div>
            
                <div onClick={props.onClick} className="menuButtonContainer" style={props.change ? {color: 'white'} : {color: '#151A21'}}>
                    <MenuButton></MenuButton>
            </div>
            </div>
        </>
    )
}


export default AboutNavbar;