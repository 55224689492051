import React from "react";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import './checkout.css';
import { ReactComponent as Logo } from '../assets/imgs/checkout/6vrytexlfu4lt8vt5s0.svg';
import { HashLink } from "react-router-hash-link";

function OrderDetail (props) {
    const formData = props.formData;
    /*    
    const formData = {
        email: 'john@example.com',
        phone: '+123456789',
        name: 'John',
        lastname: 'Smith',
        address: '123 Main St',
        floor: '3',
        city: 'New York',
        country: 'US',
        zipcode: '12345'
    };
    */
    const plan = useParams();

 
        const handleFinalizarClick = () => {
          // Reload the page
          window.location.reload();
          // Redirect the user to the root
          window.location.href = '/';
        };

    return (
        <>

        <div className="centering-container" style={{backgroundColor: 'rgba(211, 211, 200, 0.044)'}}>
            <div className="max-width-container">
                <div className="order-detail-container mont400">
                        <Logo></Logo>
                        <div className="montBold">Plan {plan.selection === 'semestral' ? 'Semestral' : 'Anual'}</div>
                    <div className="order-detail-header montBold" style={{fontSize: '20px'}}>
                        ¡Gracias por tu compra!
                    </div>
                    <div className="order-detail-header mont400" style={{fontSize: '16px'}}>
                        Un email de confirmación ha sido enviado a tu correo.
                    </div>
                    <div className="order-summary-container" style={{marginTop: '0px'}}>
                        
                        <div className="client-info-row" style={{marginTop: '10px', marginBottom: '50px'}}>
                            <div className="montBold">Numero de orden: </div>
                            <div>{props.orderID}</div>
                        </div>
                        <div style={{width: '30vw', border: '1px solid lightgrey', marginBottom: '50px'}}></div>
                        <div className="client-info-container">
                            <div className="client-info-row">
                            <div className="montBold">Nombre</div>
                            <div>{formData.name + ' ' + formData.lastname}</div>
                            </div>
                            <div className="client-info-row">
                            <div className="montBold">Email:</div>
                            <div> {formData.email}</div>
                            </div>
                            <div className="client-info-row">
                            <div className="montBold">Telefono:</div>
                            <div> {formData.phone}</div>
                            </div>
                            <div className="client-info-row">                               
                            <div className="montBold">Dirección:</div>
                            <div> {formData.address + ' ' + formData.floor + ' ' + formData.city + ' ' + formData.country + ' ' + formData.zipcode}</div>
                            </div>
                        </div>
                    </div>
                    <HashLink to={'/'}>
                    <button className="buttonBlue" style={{alignSelf: 'center', marginTop: '40px', marginBottom: '40px'}} onClick={handleFinalizarClick}>Finalizar</button>
                    </HashLink>
                </div>
            </div>
        </div>

        </>
    )
}

export default OrderDetail;