import React from "react";
import { useEffect, useState, useRef } from "react";

import {v4 as uuidv4} from 'uuid';

import {ReactComponent as Arrow} from '../assets/imgs/arrowPlanes.svg';

import './accordeonrowplanes.css';
import { ButtonNB } from "./ButtonNB";
import { Link } from "react-router-dom";

function AccordeonRowPlanes(props) {
    
        
    const [idRow, setIdRow] = useState(uuidv4());
    const [buttonId, setButtonId] = useState(uuidv4());
    const [rowTextId, setRowTextId] = useState(uuidv4());

    const [clicked, setClicked] = useState(false);
    const [height, setHeight] = useState('65.99px');



    useEffect(() => {

        if(props.isClicked) {
            const newHeight = (1.1 * (document.getElementById(idRow).offsetHeight + document.getElementById(rowTextId).offsetHeight)) + 'px';
            setClicked(true);
            setHeight(newHeight);
        } else {
            setClicked(false);
            setHeight('65.99px')
        }

    }, [props.isClicked, idRow, rowTextId])



    return (
        <>
    
   

    <div id={idRow} className={'rowWrapperPlanes'} style={{height: height}} onClick={props.onClick}>

    <div className="rowTitleWrapperPlanes">

        <div className="rowTitleContainerPlanes">

            <div className="rowTitlePlanes">
                {props.title}
            </div>
        </div>
        <div id={buttonId} className="accordeonButtonPlanes" style={props.isClicked ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}>
            <div className="accordeonIconPlanes">
                <Arrow></Arrow>
            </div>
        </div>

    </div>
    <div id={rowTextId} className="rowTextContainerPlanes">
        <div className="rowTextPlanes">
            <div className="planList">
                {props.list}
            </div>
            <div className="planesText">
                {props.message}
            </div>
        </div>

        <div className="bottomRowLinksPlanes">
            <ButtonNB text={"Entrar en contacto"} link={'/contacto'}></ButtonNB>
            <Link to={'https://google.com'} target="_blank">
            <div className="downloadText">Descargar planificación</div>
            </Link>
        </div>

    </div>
    </div>

    

        </>
    )
}

export default AccordeonRowPlanes;