import React, { useEffect } from 'react'
import { useState, useRef } from 'react';

import './planes.css';
import { PlanesNavbar } from '../Components/PlanesNavbar';
import { ButtonNB } from '../Components/ButtonNB';
import Footer from '../Home/Footer/Footer';

import dnaplan from '../assets/imgs/footer/footer-plan/dnaplan.webp';
import fruitsplan from '../assets/imgs/footer/footer-plan/dryfruits.webp';
import wdance from '../assets/imgs/footer/footer-plan/wdance.webp';
import PlanDescription from '../Components/PlanDescription';

import { NavBar } from '../Home/NavBar/NavBar';
import MobileMenu from '../Menu/MobileMenu';
import PlanesMobile from './PlanesMobile';

import WhatsAppLink from '../Components/WhatsAppLink';

function Planes() {

  const [open, setOpen] = useState(false);

  const openMenu = () => {
    if(open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const [active, setActive] = useState(0);
  
  
  useEffect( () => {
    
    document.getElementById('anual').addEventListener("click", () => {
   
      setActive(0);

    })

    document.getElementById('semestral').addEventListener("click", () => {
    
      setActive(1);

    })


  }, [])

  useEffect(() => {
    adjustSize(731, 864);
  }, [])


 
  const adjustSize = (x, y) => {
    
    let tag = document.querySelector('.planColumn2');
    tag.style.width = x + 'px';
    tag. style.height = y + 'px';
   
  }


  const planAnual = {
    plan: 0,
    title: 'Plan anual:',
    list: <ul><li>4 extracciones</li><li>Plan de acción personalizado</li><li>Contenido semanal digital</li></ul>,
    text: 'Con nuestro servicio de Biostracker, obtiendrás una experiencia completa para potenciar tu salud. Con las extracciones trimestrales de sangre desde la comodidad de tu casa, nuestro equipo de profesionales analiza tus biomarcadores y te brinda información detallada sobre tu estado de salud. Armaremos en conjunto un plan accionadle que nos permita trabajar sobre tus resultados y te acompañaremos en la implementación de ellos. Además podrás recibir contenido digital semanal con tips, recetas e información para potenciar el avance.'
}

const planSemestral = {
    plan: 1,
    title: 'Plan semestral:',
    list: <ul><li>2 extracciones</li><li>Plan de acción personalizado</li><li>Contenido semanal digital</li></ul>,
    text: 'Podrás descrubrir con la primer extracción de sangre lo que tu cuerpo necesita. Luego junto con el plan de acción recomendado y el acompañamiento, podremos volver a evaluar los biomarcadores para ver los avances.  Además, recibirás contenido digital semanalmente para además nutrir el conocimiento.'
}



  return (
    <>

    <WhatsAppLink></WhatsAppLink>

    <MobileMenu menu={open} onClick={openMenu}></MobileMenu>
    

    <div className='mainBackgroundWrapper'>

       <div className='hide'>
      <PlanesNavbar></PlanesNavbar>
      </div>
      
      <div className='hideDesktop'>
      
    
      <NavBar change={true} onClick={openMenu}></NavBar>
      </div>

      <div id='top' className='headerPlanesContainer'>
          <div className='headerPlanesText mont400'>
              Mantenete <br/> en movimiento
          </div>
      </div>


      <div className='hide'>
      <div className='planOuterWrapper'>
        
        <div className='planColumn1'>

            <div id="anual" className='planSelectionButton' style={active != 0 ? {color: '#BCD8FF', borderColor: '#BCD8FF'} : {}}>
                ANUAL
            </div>

            
            <div id="semestral" className='planSelectionButton' style={active != 1 ? {color: '#BCD8FF', borderColor: '#BCD8FF'} : {}}>
                SEMESTRAL
            </div>

        </div>
        
        <div id="plancolumn" className='planColumn2 mont400'>

            <PlanDescription
              active={active}
              info={planAnual}
              adjust={adjustSize}
              
            >
            </PlanDescription>
            <PlanDescription
              active={active}
              info={planSemestral}
              adjust={adjustSize}
              
            >
            </PlanDescription>

        </div>

      </div>
      </div>
      {/*/hide*/}

      <div className='hideDesktop'>
        <PlanesMobile></PlanesMobile>
      </div>
      {/*pic2={fruitsplan} styleR={{width: 'clamp(1px, 39.23vw, 565', height: 'clamp(1px, 39.23vw, 565px', marginBottom: '31vw'}}
      pic1={dnaplan} styleT={{width: 'clamp(1px, 32.56vw, 469px', height: 'clamp(1px, 32.56vw, 469px', marginRight: '25vw'}}
      plan={true} styleL={{width: 'clamp(1px, 28.26vw, 407px', height: 'clamp(1px, 28.26vw, 407px'}}*/}
      <Footer 
      plan={true} styleL={{width: 'clamp(1px, 28.26vw, 390px', height: 'clamp(1px, 28.26vw, 390px'}}
      pic1={dnaplan} styleT={{width: 'clamp(1px, 32.56vw, 400px', height: 'clamp(1px, 32.56vw, 400px', marginRight: '25vw'}}
      pic2={fruitsplan} styleR={{width: 'clamp(1px, 39.23vw, 520px', height: 'clamp(1px, 39.23vw, 520px', marginBottom: '31vw'}}
      pic3={wdance}
      ></Footer>

    </div>
    </>
  )
}

export default Planes;
