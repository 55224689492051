import React from "react";

import whatsappLogo from '../assets/wpLogo.webp';

import { Link } from "react-router-dom";

function WhatsAppLink () {
    
    const wpStyles = {
        position: 'fixed',
        bottom: '3rem',
        right: '3rem',
        width: '5rem',
        height: '5rem',
        zIndex: '9999999999',
    }

    return (
        <>
        
        <Link to={"https://wa.me/1234"} target="_blank">
            <img src={whatsappLogo} style={wpStyles}></img>
        </Link>

        </>
    )

}

export default WhatsAppLink;