import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home/Home';
import  About  from './About/About';
import  Planes  from './Planes/Planes';
import { NavBar } from './Home/NavBar/NavBar';
import Contacto from './Contacto/Contacto';
import Article from './Articles/Article';
import Checkout from './Checkout/Checkout';
import OrderSelection from './Checkout/OrderSelection';


function App(){

    return (
        <Router>   
            <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route exact path={"/quienes-somos"} element={<About/>}/>
                <Route exact path={"/planes"} element={<Planes/>}/>
                <Route exact path={"/contacto"} element={<Contacto/>}/>
                <Route exact path={"/article/:name"} element={<Article/>}/>
                <Route exact path={"/checkout/:selection/:type"} element={<Checkout/>}/>
                <Route exact path={"/order"} element={<OrderSelection/>}/>
            </Routes>
        </Router>
        
    );
}

export default App;