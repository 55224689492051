import React, { useState, useEffect } from "react";
import { ReactComponent as LoadingIcon } from '../assets/imgs/loading/loading.svg';
import './loading.css';

function Loading(props) {
  const [colorStyle, setColorStyle] = useState(randColor());

  useEffect(() => {
    // Set up an interval to change the color every 2 seconds
    const intervalId = setInterval(() => {
      setColorStyle(randColor());
    }, 400);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  function randColor() {
    const hexCode = Math.floor(Math.random() * 16777215).toString(16);
    return {
      color: '#' + '0'.repeat(6 - hexCode.length) + hexCode,
      transition: 'all ease 1s'
    };
  }

  useEffect(() => {
    if (!sessionStorage.getItem('hasLoadedBefore')) {

        let tag = document.querySelector('.loadingContainer');
        if (!props.load) {
            tag.style.display = 'none';
        } else {
            tag.style.display = 'flex';
        }
        sessionStorage.setItem('hasLoadedBefore', 'true');
    }
  }, [props.load])



  if (!sessionStorage.getItem('hasLoadedBefore')) {

  return (
    
    <div className="loadingContainer" style={props.load ? {opacity: 1} : {opacity: 0}}>
      <div style={colorStyle}>
        <LoadingIcon />
      </div>
    </div>
  );
}


}

export default Loading;
