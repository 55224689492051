import React from "react";
import { useState, useEffect, useRef } from "react";

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import axios from "axios";

function Subscription(props) {

    const [success, setSuccess] = useState(false);

    const formData = props.formData;
    const subscriptionId = useRef("");
   
    const createSubscription = async () => {
        try {
            const response = await fetch("https://api.biostracker.com/paypal/create-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: props.plan + '_subscription',
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to create order');
            }
    
            //const orderID = await response.text(); 
            const orderID  = await response.text();
            subscriptionId.current = orderID;
            console.log("Order ID:", orderID);
    
            return orderID;
        } catch (error) {
            console.error('Error creating order:', error);
            throw error;
        }
    };

    const logData = () => {
        let data = JSON.stringify({
            "orderID": subscriptionId.current,
            "email": formData.email,
            "phone": formData.phone,
            "name": formData.name,
            "lastname": formData.lastname,
            "address": formData.address,
            "floor": formData.floor,
            "city": formData.city,
            "country": formData.country,
            "zipcode": formData.zipcode,
            "isPayed": true,
            "isSubscription": true,
            "plan": props.plan,
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.biostracker.com/sheets/logorder',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            props.setPayed(true, subscriptionId.current);
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
          
    }



	return (
        <>
        
        <div className="centering-container">

            <div className="max-width-container">

                        
                <div className="pay-block-container">
        <PayPalScriptProvider
        options={{
            clientId: process.env.REACT_APP_PP_CLIENT_ID,
            components: "buttons",
            intent: "subscription",
            vault: true,
        }}
    >
    <PayPalButtons
		createSubscription={createSubscription}
		style={{
			label: "subscribe",
		}}
        onApprove={(data, actions) => {logData()}}
       
	/>
    </PayPalScriptProvider>
    </div>
    </div>
    </div>
    </>
    );


}

export default Subscription;