import React from "react";
import AboutNavbar from "../Components/AboutNavbar";

import {useState, useRef} from 'react';

import almonds from '../assets/imgs/footerabout/almonds-footer.webp';
import salad from '../assets/imgs/footerabout/salad-footer.webp';
import cells from '../assets/imgs/footerabout/cells-footer.webp';

import './contacto.css';
import '../Components/buttonnb.css';

import AccordeonRowFAQ from "../Components/AccordeonRowFAQ";
import Footer from "../Home/Footer/Footer";
import { ButtonNB } from "../Components/ButtonNB";
import MobileMenu from "../Menu/MobileMenu";

import WhatsAppLink from '../Components/WhatsAppLink';


function Contacto() {

    //rebuild

    const styleAlm = {
        width: 'clamp(1px, 29.58vw, 426px)',
        height: 'clamp(1px, 29.58vw, 426px)',
        bottom: 0
      }
    
      const styleCells = {
        width: 'clamp(1px, 29.02vw, 418px)',
        height: 'clamp(1px, 29.02vw, 418px)',
        marginLeft: '20vw'
      }
    
      const styleSalad = {
        width: 'clamp(1px, 33.88vw, 488px',
        height: 'clamp(1px, 33.88vw, 488px',
        marginBottom: 'clamp(1px, 2.98vw, 43px)'
      }

      

    const acordeones = [
        {
            paso: 'Primer paso',
            title: '¿Qué son los biomarcadores?',
            message: 'Los Biomarcadores son sustancias en sangre que nos sirven para predecir, diagnosticar y monitorear el estado de salud, tanto como el impacto de las intervenciones del estilo de vida.',
            turn: 1
        },
        {
            paso: 'Primer paso',
            title: '¿Cómo funciona la extracción de sangre?',
            message: 'Pautaremos una fecha y horario para que un equipo experto te visite en tu domicilio. Se limpiará el área de punción, se colocará un torniquete para aumentar el flujo sanguíneo, se seleccionará una vena, se introducirá una aguja para extraer la muestra y recoger en tubos de ensayo. Luego eso se llevará al laboratorio para ser analizado.',
            turn: 2
        },
        {
            paso: 'Tercer paso',
            title: '¿Con cuánto tiempo de anticipación debo coordinar la extracción?',
            message: 'Se recomienda coordinar con 1 semana de anticipación.',
            turn: 3
        },
        {
            paso: 'Tercer paso',
            title: '¿A qué zonas llega Biostracker?',
            message: 'Estamos en CABA, GBA y La Plata.',
            turn: 4
        },
        {
            paso: 'Tercer paso',
            title: '¿Qué métodos de pago tengo?',
            message: 'Ese día, desde la comodidad de tu domicilio, obtenemos una muestra de sangre para poder analizar en laboratorio y comenzar a evaluar el estado del paciente en profundidad',
            turn: 4
        },
        {
            paso: 'Tercer paso',
            title: '¿En cuánto tiempo puedo ver cambios en mis biomarcadores?',
            message: 'Cambios en la dieta, el sueño, y el ejercicio pueden afectar los niveles en semanas a meses.',
            turn: 4
        }
        
    ]

    //const [childStates, setChildStates] = useState(Array(acordeones.length).fill(false));
    //const activeIndex = useRef(0);
    const [activeIndex, setActiveIndex] = useState(null);
    const [childStates, setChildStates] = useState(Array(acordeones.length).fill(false));
    const firstClick = useRef(false);
    const [turno, setTurno] = useState(null);

    const handleTurn = (turn) => {
        
       
        setTurno(turn);

    }


    const handleChildClick = (childIndex, turn) => {
        if (!firstClick) {
            firstClick.current = true;
            const updatedChildStates = Array(acordeones.length).fill(false);
            updatedChildStates[childIndex] = true;
            setChildStates(updatedChildStates);
            setActiveIndex(childIndex);
        } 
        else {
            if (childIndex == activeIndex) {

                setChildStates(Array(acordeones.length).fill(false));
                setActiveIndex(null);
            }
            else {
                const updatedChildStates = Array(acordeones.length).fill(false);
                updatedChildStates[childIndex] = true;
                setChildStates(updatedChildStates);
                setActiveIndex(childIndex);
            }
        }

        handleTurn(turn);
    
    }

    const handleSubmit = (e) => {
        
        
    }

    const [open, setOpen] = useState(false);

    const openMenu = () => {
      if(open) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }

    const assignHeight = window.innerWidth > 479 ? 'clamp(1px, 2.63vw, 38px' : 'max(6.52vw, 27px)';

    return (
        <>

        <WhatsAppLink></WhatsAppLink>

        <MobileMenu menu={open} onClick={openMenu}></MobileMenu>

        <div style={{paddingBottom: 'clamp(1px, 9.72vw, 140px)'}}>
            
            <AboutNavbar change={false} menu={open} onClick={openMenu}></AboutNavbar>
            
        <div className="hMessageContainer">
            <div className="headerMessageC">
                Nos gustaría <br/>entrar en contacto
            </div>

            {/*TODO: Finish netlify setup*/}
            <div className="formWrapper">
                <form onSubmit={handleSubmit} className="formContainer" name="contact" data-netlify="true">
                    <input type="hidden" name="form-name" value="hiddenField" />
                    <input maxLength={100} type="text" placeholder="nombre" className="formInput mont400" required></input>
                    <input maxLength={100} type="text" placeholder="mail" className="formInput mont400" required></input>
                    <textarea maxLength={8000} type="text" placeholder="mensaje" className="formInput mont400" style={{padding: 'inherit', height: assignHeight}}></textarea>
                    <div className="buttonBlue mont400" > {/*   font-size: clamp(1px, 1.66vw, 24px); margin-top: clamp(1px, 1.25vw, 18px);*/}
                        <button type="submit">Enviar</button>
                        {/*https://www.npmjs.com/package/react-google-recaptcha*/}
                    </div>
                </form>

            </div>

        </div>


        <div className="accordeonContainerContacto">
            <div className="headerMessageC">
                preguntas <br/> frecuentes
            </div>
        {childStates.map((isClicked, index) => (
                            <AccordeonRowFAQ
                                key={index}
                                isClicked={isClicked}
                                onClick={() => handleChildClick(index, acordeones[index].turn)}
                                paso={acordeones[index].paso}
                                message={acordeones[index].message}
                                title={acordeones[index].title}
                            />
                        ))}
        </div>
        </div>

        <Footer 
      pic1={almonds} styleL={styleAlm} 
      pic2={cells} styleT={styleCells}
      pic3={salad} styleR={styleSalad}
      />
        </>
    )
}


export default Contacto;