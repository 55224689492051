import React from "react";
import { useState, useEffect } from "react";

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import TemplateCheckout from "./TemplateCheckout";

import { useNavigate, useParams } from "react-router-dom";

import OneTimePay from "./PaymentPlans/OneTimePay";

import { Navigate } from "react-router-dom";

import { NavBar } from '../Home/NavBar/NavBar';

import '../global.css';

import 'react-phone-number-input/style.css';

import PhoneInputWithCountrySelect from "react-phone-number-input";

import Subscription from "./PaymentPlans/Subscription";

import '../Components/buttonnb.css';

import FinalizePay from "./FinalizePay";

import {ReactComponent as Logo} from '../assets/imgs/checkout/6vrytexlfu4lt8vt5s0.svg';

import PurchaseForm from './PurchaseForm';

import OrderDetail from "./OrderDetail";



function Checkout () {

    const [validOrder, setValidOrder] = useState(null);
    const [enablePay, setEnablePay] = useState(false);
    
    const plan = useParams();
    const navigate = useNavigate();

    //Form parameters:

    const [formInput, setFormInput] = useState(null);

    const [orderCompleted, setOrderCompleted] = useState(false);
    
    const [orderID, setOrderID] = useState("");



    //--

    const setFormComponentData = (formData) => {
        setFormInput(formData);
        //we assume the form component did all the necessary validation before submiting the data to its parent
        setEnablePay(true);
    }



    //checks if order url is valid to avoid bad requests
    useEffect(() => {
        
        if (plan.selection) {
            if (plan.selection != "anual" && plan.selection != "semestral") {
                //alert("Plan invalido");
                setValidOrder(false);
                navigate("/order");
    
            } else {
                setValidOrder(true);
            }
        }
    }, [])

    const callBackOrderCompleted = (isPayed, orderid) => {
        setOrderCompleted(isPayed);
        setOrderID(orderid);
        console.log('From checkout.js: orderCompleted: ', orderCompleted);
    }

    const returnTotal = () => {
        return plan.selection === 'semestral' ? plan.type === 'one-time' ? 480 : 600 : plan.type === 'one-time' ? 960 : 1000
    }



    if (plan.type == "one-time" || plan.type == "subscription") {

        return (
            <>

            
            <NavBar/>
            
            {!orderCompleted ? 

            <div className="centering-container">

                <div className="max-width-container">

                    <div className="checkout-wrapper mont400">
                        { !enablePay ?
                        <div className="left-col-container">

                            <PurchaseForm setFormData={setFormComponentData}></PurchaseForm>
                            

                        </div>
                        :
                        <>
                        
                        <FinalizePay  callBackOrderCompleted={callBackOrderCompleted} plan={plan} formData={formInput}></FinalizePay>
                        
                        </>
                        
                        }

                        <div style={{width: '1px', height: 'inherit', backgroundColor: 'lightgray'}}></div>

                        <div className="right-col-container">
                            
                            <div className="summary-title montBold">
                                Resumen de la orden
                            </div>
                            <div style={{display: 'flex', gap: '10px', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <Logo></Logo>
                            <div className="subtitle">Plan {plan.selection[0].toUpperCase() + plan.selection.slice(1)}</div>
                            <div>
                                <ul>
                                    <li>{`${plan.selection === 'semestral' ? 2 : 4} extracciones`}</li>
                                    <li>Plan de acción personalizado</li>
                                    <li>Contenido semanal digital</li>
                                </ul>
                            </div>
                            </div>
                            
                            <div>
                                <div>Modalidad de pago</div>
                                <ul>
                                    {plan.type === 'one-time' ?
                                        <>
                                        <li>Pago unico</li>
                                        <li>Opciones de financiamiento</li>
                                        <li>20% OFF</li>
                                        </>
                                    : 
                                    <>
                                    <li>{plan.selection === 'semestral' ? '6' : '12'} pagos mensuales</li>
                                        <li style={{opacity: 0}}>Renovacion automatica</li>
                                    </>
                                    }
                                </ul>
                            </div>

                            <div className="total-checkout montBold">Total: {plan.selection === 'semestral' ? plan.type === 'one-time' ? 480 : 600 : plan.type === 'one-time' ? 960 : 1200} USD </div>
                            <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                            <img style={{width: '80px', height: 'auto'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/800px-PayPal.svg.png"></img>
                            <img style={{width: '50px', height: 'auto'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Visa_2014.svg/800px-Visa_2014.svg.png"></img>
                            <img style={{width: '40px', height: 'auto'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/772px-Mastercard-logo.svg.png"></img>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        : <OrderDetail formData={formInput} orderID={orderID}/>}

            </>
        )
    } else {
            
        return (

            <>
            
            Invalid Order.
            
            </>

        )
    }

}

export default Checkout;