import React, { useEffect, useState, useRef } from "react";

import './servicio.css';
import '../../global.css';


import AccordeonRow from "../../Components/AccordeonRow";
import Wheel from "../../Components/Wheel";
import { ButtonNB } from "../../Components/ButtonNB";

//Pass Arc component color= prop to change... its color

function Servicio() {

   
    const acordeones = [
        {
            paso: 'Primer paso',
            title: 'Coordinación y test',
            message: 'Ese día, desde la comodidad de tu domicilio, obtenemos una muestra de sangre para poder analizar en laboratorio y comenzar a evaluar el estado del paciente en profundidad',
            turn: 1
        },
        {
            paso: 'Segundo paso',
            title: 'Armado del plan de acción',
            message: 'En base al resultado de tus Biomarcadores, armaremos un plan de acción para potenciarlos. Tendrás la posibilidad de analizar e interpretar los resultados en una consulta virtual con un especialista en Nutrición.',
            turn: 2
        },
        {
            paso: 'Tercer paso',
            title: 'Construcción de hábitos saludables',
            message: 'Semanalmente recibirás tips, recetas e información útil para la creación de hábitos saludables que te permitirán mantener tus Biomarcadores en su rango óptimo.',
            turn: 3
        },
        {
            paso: 'Cuarto paso',
            title: 'Re-evaluación y seguimiento',
            message: 'Al cabo de 3 meses, repetiremos la extracción a domicilio para ver los resultados de tus hábitos. Te acompañaremos para sostener las intervenciones que te benefician y adaptaremos el plan de acción a tus necesidades.',
            turn: 4
        }
        
    ]

    //const [childStates, setChildStates] = useState(Array(acordeones.length).fill(false));
    //const activeIndex = useRef(0);
    const [activeIndex, setActiveIndex] = useState(null);
    const [childStates, setChildStates] = useState(Array(acordeones.length).fill(false));
    const firstClick = useRef(false);
    const [turno, setTurno] = useState(null);

    const handleTurn = (turn) => {
        
      
        setTurno(turn);

    }


    const handleChildClick = (childIndex, turn) => {
        if (!firstClick) {
            firstClick.current = true;
            const updatedChildStates = Array(acordeones.length).fill(false);
            updatedChildStates[childIndex] = true;
            setChildStates(updatedChildStates);
            setActiveIndex(childIndex);
        } 
        else {
            if (childIndex == activeIndex) {

                setChildStates(Array(acordeones.length).fill(false));
                setActiveIndex(null);
            }
            else {
                const updatedChildStates = Array(acordeones.length).fill(false);
                updatedChildStates[childIndex] = true;
                setChildStates(updatedChildStates);
                setActiveIndex(childIndex);
            }
        }

        handleTurn(turn);
    
    }


    return (
        <>
        <div className="backgroundCircle"></div>

        <div className="servicioOuterWrapper">
            
            <div id="servicio" className="servicioTitle mont400">Nuestro servicio</div>

            <div className="servicioContainer">
                
                <Wheel turno={turno}/>

                <div className="servicioRightCol mont400">

                          {childStates.map((isClicked, index) => (
                            <AccordeonRow
                                key={index}
                                isClicked={isClicked}
                                onClick={() => handleChildClick(index, acordeones[index].turn)}
                                paso={acordeones[index].paso}
                                message={acordeones[index].message}
                                title={acordeones[index].title}
                            />
                        ))}
                        <div className="hide" style={{marginTop: 'clamp(1px, 3.125vw, 45px'}}>
                            <ButtonNB text={"Saber más"} link={"/quienes-somos"} hash={"top"}></ButtonNB>
                        </div>
                </div>

                <div className="linkUnderMobile">
                    <ButtonNB text={"Saber más"} link={"/quienes-somos"} hash={"top"}></ButtonNB>
                </div>

            </div>

        </div>
        </>
    )

}

export default Servicio;