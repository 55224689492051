import React from 'react'
import './buttonnb.css';
import '../global.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const ButtonNB = (props) => {



  const dis = props.disable === true ? {pointerEvents: 'none'} : {};

  const hash = props.hash ? '#' + props.hash : '';
  
  return (

    <HashLink to={props.link + hash} style={{width:'fit-content', ...dis}}>
    <div className='buttonBlue mont400'>
      {props.text}
    </div>
    </HashLink>
  )
}

ButtonNB.defaultProps = {
  disable: false,
}