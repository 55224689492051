import React from "react";

import FooterCard from "../../Components/FooterCard";

import Dna from '../../assets/imgs/footer/dnacut.webp';
import Leaf from '../../assets/imgs/footer/leafcut.webp';
import PinkCells from '../../assets/imgs/footer/pinkcells.webp';

import './footer.css';

import { useRef } from "react";

function Footer(props) {



    return (
        <>


        <div className="footerOuterWrapper">

            <img  className="dnacut" src={props.pic3} style={props.styleR}/>
            <img  className="leafcut" src={props.pic1} style={props.styleL}/> 
            <img className="pinkcut" src={props.pic2} style={props.styleT}/>

            <FooterCard plan={props.plan} ></FooterCard>

            <div className="footerBottomText hide" style={props.plan ? {color: 'white'} : {}}>
                Copyright © 2023 | BIOSTRACKER® . Todos los derechos reservados
            </div>

        </div>

        </>
    )
}

export default Footer;