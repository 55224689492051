import React from "react";

import './cellphones.css';
import '../global.css';

import biosName from '../assets/imgs/cellphones/biosTrackerName.webp';
import graph1 from '../assets/imgs/cellphones/graph1.webp';
import arrowIcon from '../assets/imgs/cellphones/arrowIcon.webp';
import graph2 from '../assets/imgs/cellphones/graph2.webp';



function LeftCellphone() {
    
    return (
        <>
        <div id="leftbox" className='cellphoneBox'>
            <img src={biosName} className='biosName'/>
            <div className='innerBoxOuterWrapper'></div>
            <div className='innerBoxWrapper'>
               <div className='innerTitleContainer'>
                  <div className='innerTitle mont400'>
                     Cortisol
                  </div>
                  <div className='innerSubtitle mont400'>
                     Producido por las glándulas suprarrenales  
                  </div>
               </div>
               <img className='graph1' src={graph1}></img>
            </div>
            <div className='innerBoxWrapper'>
               <div className='innerTitleContainer'>
                  <div className='innerTitle mont400'>
                     HDL
                  </div>
                  <div className='innerSubtitle mont400'>
                     LEl HDL es la lipoproteína de alta densidad <br/>
                     producida por el hígado
                  </div>
               </div>
               <img className='graph1' src={graph2}></img>
            </div>
            <div className='verMasResultados mont400'>
               <div>
                  Ver más resultados
               </div>
               <img className='verMasArrow' src={arrowIcon}></img>
            </div>
      </div> {/*cellphoneBox End */}
        </>
    )
}

export default LeftCellphone;