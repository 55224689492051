import React from "react";

import { useEffect, useState, useRef } from "react";

import {v4 as uuidv4} from 'uuid';

import './accordeonrowfaq.css';
import '../global.css';


import {ReactComponent as Cross} from '../assets/imgs/contacto/+.svg';


function AccordeonRowFAQ(props) {

   
    
    const [idRow, setIdRow] = useState(uuidv4());
    const [buttonId, setButtonId] = useState(uuidv4());
    const [rowTextId, setRowTextId] = useState(uuidv4());


    //const wrapperClassName = props.isClicked ? "rowWrapperContacto clicked" : "rowWrapperContacto";
    
    /*
    let styles = '';

    if (props.isClicked) {
        
        styles = {
            height: (1.2 * (document.getElementById(idRow).offsetHeight + document.getElementById(rowTextId).offsetHeight)) + 'px',
        }
    } else {
        styles = {
            height: '124.96px', 
            
        }
    }
    */

    const [clicked, setClicked] = useState(false);
    const [height, setHeight] = useState('124.96px');
    const [hideText, setHideText] = useState(true);


    useEffect(() => {

        if(props.isClicked) {

           

            const newHeight = (1.2 * (document.getElementById(idRow).offsetHeight + document.getElementById(rowTextId).offsetHeight)) + 'px';
            setClicked(true);
            setHeight(newHeight);
            setHideText(false);
        } else {
            setClicked(false);
            if (window.innerWidth > 900) {
                setHeight('124.96px')
            } else {
                setHeight('100px')
            }
            setHideText(true);
        }

    }, [props.isClicked, idRow, rowTextId])

    return (
<>
    
    <div id={idRow} className={'rowWrapperContacto'} style={{height: height}} onClick={props.onClick}>

        <div className="rowTitleWrapperContacto">

            <div className="rowTitleContainerContacto">

                <div className="rowTitleContacto mont400">
                    {props.title}
                </div>
            </div>
            <div id={buttonId} className="accordeonButtonContacto">
                <div className="accordeonIconContacto">
                    <Cross></Cross>
                </div>
            </div>

        </div>
        <div id={rowTextId} className="rowTextContainerContacto">
            <div className="rowTextContacto mont400">
                {props.message}
            </div>
        </div>
    </div>
</>
    )

}

export default AccordeonRowFAQ;