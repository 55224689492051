import React from "react";

import './planes.css';
import AccordeonRowPlanes from "../Components/AccordeonRowPlanes";

import { useState, useRef } from "react";

function PlanesMobile() {




    const acordeones = [
        {
            paso: 'Primer paso',
            title: 'ANUAL',
            list: <ul><li>4 extracciones</li><li>Plan de acción personalizado</li><li>Contenido semanal digital</li></ul>,
            message: 'Con nuestro servicio de Biostracker, obtiendrás una experiencia completa para potenciar tu salud. Con las extracciones trimestrales de sangre desde la comodidad de tu casa, nuestro equipo de profesionales analiza tus biomarcadores y te brinda información detallada sobre tu estado de salud. Armaremos en conjunto un plan accionadle que nos permita trabajar sobre tus resultados y te acompañaremos en la implementación de ellos. Además podrás recibir contenido digital semanal con tips, recetas e información para potenciar el avance.',
            turn: 1
        },
        {
            paso: 'Primer paso',
            title: 'SEMESTRAL',
            list: <ul><li>2 extracciones</li><li>Plan de acción personalizado</li><li>Contenido semanal digital</li></ul>,
            message: 'Podrás descrubrir con la primer extracción de sangre lo que tu cuerpo necesita. Luego junto con el plan de acción recomendado y el acompañamiento, podremos volver a evaluar los biomarcadores para ver los avances. Además, recibirás contenido digital semanalmente para además nutrir el conocimiento.',
            turn: 2
        }
        
    ]

    const [activeIndex, setActiveIndex] = useState(null);
    const [childStates, setChildStates] = useState(Array(acordeones.length).fill(false));
    const firstClick = useRef(false);
    const [turno, setTurno] = useState(null);

    const handleTurn = (turn) => {
        
        setTurno(turn);

    }


    const handleChildClick = (childIndex, turn) => {
        if (!firstClick) {
            firstClick.current = true;
            const updatedChildStates = Array(acordeones.length).fill(false);
            updatedChildStates[childIndex] = true;
            setChildStates(updatedChildStates);
            setActiveIndex(childIndex);
        } 
        else {
            if (childIndex == activeIndex) {

                setChildStates(Array(acordeones.length).fill(false));
                setActiveIndex(null);
            }
            else {
                const updatedChildStates = Array(acordeones.length).fill(false);
                updatedChildStates[childIndex] = true;
                setChildStates(updatedChildStates);
                setActiveIndex(childIndex);
            }
        }

        handleTurn(turn);
    
    }




    return (
        <>
        
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', 
        justifyContent: 'center', gap: '6.52vw', marginTop: '18.11vw', marginBottom: '43vw'}}>

                    {childStates.map((isClicked, index) => (
                            <AccordeonRowPlanes
                                key={index}
                                isClicked={isClicked}
                                onClick={() => handleChildClick(index, acordeones[index].turn)}
                                paso={acordeones[index].paso}
                                message={acordeones[index].message}
                                title={acordeones[index].title}
                                list={acordeones[index].list}
                            />
                        ))}

        
        </div>
        </>
    )
}

export default PlanesMobile;