import React from "react";

import { useState, useEffect } from "react";
import OneTimePay from "./PaymentPlans/OneTimePay";
import Subscription from "./PaymentPlans/Subscription";
import OrderDetail from "./OrderDetail";

import {ReactComponent as Logo} from '../assets/imgs/checkout/6vrytexlfu4lt8vt5s0.svg';

function FinalizePay(props) {

    const [plan, setPlan] = useState(props.plan);

    const formData = props.formData;
    /*
    const formData = {
        email: 'john@example.com',
        phone: '+123456789',
        name: 'John',
        lastname: 'Smith',
        address: '123 Main St',
        floor: '3',
        city: 'New York',
        country: 'US',
        zipcode: '12345'
    };
    */

    const [paymentDone, setPaymentDone] = useState(true);
    
    const callbackOrderCompleted = (isPayed, orderid) => {
        if (isPayed) {
            
            setPaymentDone(true);
            console.log('finalize pay.js ', isPayed);
            props.callBackOrderCompleted(true, orderid);

        }
    }

    const cleanUpAfterPurchase = () => {
        props.cleanUp();
    }

    return (
        <>
        
        <div className="centering-container">
            <div className="max-width-container">

                <div className="resumen mont400">

                <div style={{marginBottom: '40px', display: 'flex', gap: '10px', alignItems: 'center'}}><Logo/><div className="montBold">Informacion del cliente</div></div>

                
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    
                
                    <div style={{display: 'flex', flexDirection: 'column', gap: '1px', lineHeight: '100%'}}>
                        <h4 className="montBold">Nombre</h4>
                        <div>{formData.name + ' ' + formData.lastname}</div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '1px', lineHeight: '100%'}}>
                            <h4 className="montBold">Email</h4>
                            <div>{formData.email}</div>
                        </div>
                        { formData.phone != "" ? 
                        <div style={{display: 'flex', flexDirection: 'column', gap: '1px', lineHeight: '100%'}}>
                            <h4 className="montBold">Telefono</h4>
                            <div>{formData.phone}</div>
                        </div>
                        : null
                        }
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '1px', lineHeight: '100%'}}>
                        <h4 className="montBold">Direccion</h4>
                        <div>{formData.address + ', ' + formData.city + ' ' + formData.zipcode + ', ' + formData.country}</div>
                    </div>

                </div>

                <div style={{marginTop: '10%'}}></div>

                {plan.type == 'one-time' ? 
                    <OneTimePay  setPayed={callbackOrderCompleted} formData={formData} plan={plan.selection} enable={true}/>
                    : 
                    <Subscription  setPayed={callbackOrderCompleted} formData={formData} plan={plan.selection}/>
                }
                
                </div>

            </div>

        </div>
        
        </>
    )
}


export default FinalizePay;