import React from "react";
import './navbar.css';
import BiosLogo from '../../assets/BiosLogo.webp';
import BiosName from '../../assets/BiosName.webp';
import {ReactComponent as MenuButton} from '../../assets/menuButton.svg';
import {ReactComponent as BiosLogoSVG} from '../../assets/BiosLogoSVG.svg';
import '../../global.css';
import { Link } from "react-router-dom";

import {  useState } from "react";
import { ContactoButton } from "../../Components/ContactoButton";
import MobileMenu from "../../Menu/MobileMenu";


export const NavBar = (props) => {




    return (
        <>
        <div className="navContainer">
            <Link to={'/'} onClick={props.callbackClose}>
            <div className="logoContainer">
                {/*<img className="biosLogo" src={BiosLogo}/>*/}
                <div className="biosLogo" style={props.change ? {color: 'white'} : {color: '#151A21'}}><BiosLogoSVG/></div>
                <img className="biosName" src={BiosName}/>
            </div>
            </Link>
            
            <div className="linksContainer mont400">
                <Link to={"/quienes-somos"}>
                    <p className="linkContainerChild ">Sobre nosotros</p>
                </Link>
                <Link to={"/quienes-somos"}>
                    <p className="linkContainerChild ">Como funciona</p>
                </Link>
                <Link to={"/planes"}>
                    <p className="linkContainerChild ">Nuestros planes</p>
                </Link>
            </div>
            <div className="hide">
                <ContactoButton text={'Contacto'} link={'/contacto'} change={props.change}></ContactoButton>
            </div>

            <div onClick={props.onClick} className="menuButtonContainer" style={props.change ? {color: 'white'} : {color: '#151A21'}}>
                <MenuButton></MenuButton>
            </div>


        </div>

         </>
    )
}