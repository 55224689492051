import React from "react";

import '../Home/Footer/footer.css';

import {ReactComponent as BiosLogoFooter} from '../assets/imgs/footer/biosLogoFooter.svg';
import {ReactComponent as VolverArrow} from '../assets/imgs/footer/volverArrow.svg';

import {ReactComponent as FbIcon} from '../assets/imgs/footer/fbicon.svg';
import {ReactComponent as InstaIcon} from '../assets/imgs/footer/insta.svg';
import {ReactComponent as TwitterIcon} from '../assets/imgs/footer/twitter.svg';

import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

function FooterCard(props) {

    

    return (
        <>
        <div className="footerWrapper" style={props.plan ? {background: 'rgba(56, 76, 101, 0.60)'} : {}}>
            
            
            <div className="volverArrowContainerMobile">

            <Link onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }}>
                <VolverArrow className="volverArrow"></VolverArrow>
            </Link>
            

            <div className="volverArribaText mont400">
                Volver arriba
            </div>

            </div>

            <Link to={'/'}>
            <BiosLogoFooter className="biosLogoFooter" style={props.plan ? {color: 'white'} : {}}></BiosLogoFooter>
            </Link>
            
            <div className="footerLinkRow montSemiBold" style={props.plan ? {color: 'white'} : {}}>

                <HashLink to={"/quienes-somos#root"}>
                    <div className="innerFooterLink">Sobre nosotros</div>
                </HashLink>
                <HashLink to={"/quienes-somos#servicio"}>
                    <div className="innerFooterLink">Cómo funciona</div>
                </HashLink>
                <HashLink to={"/planes#root"}>
                    <div className="innerFooterLink">Nuestros planes</div>
                </HashLink>

            </div>

            <div className="footerHeaderContainer" style={props.plan ? {color: 'white'} : {}}>
                No se trata solamente de vivir más, <br/> sino de vivir mejor.
            </div>

            <div className="redesFooterContainer" style={props.plan ? {color: 'white'} : {}}>
                <Link to={"https://facebook.com"}>
                    <FbIcon className="fbicon"></FbIcon>
                </Link>
                <Link to={"https://instagram.com"}>
                    <InstaIcon className="instaicon"></InstaIcon>
                </Link>
                <Link to={"https://twitter.com"}>
                    <TwitterIcon className="twittericon"></TwitterIcon>
                </Link>
            </div>

            <div className="volverArribaText mont400 hide">
                Volver arriba
            </div>
            
            <div className="footerBottomText hideDesktop" style={props.plan ? {color: 'white'} : {}}>
                Copyright © 2023 | BIOSTRACKER® . <br/> Todos los derechos reservados
            </div>

        </div>
        </>
    )
}


export default FooterCard;