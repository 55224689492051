import React from 'react';
import './cellphones.css';
import '../global.css';

import bottleIcon from '../assets/imgs/cellphones/bottleIcon.webp';
import manIcon from '../assets/imgs/cellphones/manIcon.webp';
import waveIcon from '../assets/imgs/cellphones/waveIcon.webp';
import appleIcon from '../assets/imgs/cellphones/appleIcon.webp';
import biosName from '../assets/imgs/cellphones/biosTrackerName.webp';
import biosLogoIcon from '../assets/imgs/cellphones/biosLogoIcon.webp';
import LeftCellphone from './LeftCellphone';

import { useEffect, useRef } from 'react';
import RightCellphone from './RightCellphone';
import { ButtonNB } from './ButtonNB';

export const Cellphones = () => {

   /*Test Code*/ 
   
   const animatedElementRef = useRef(null);

   useEffect(() => {
      
      const options = {
        root: null, 
        rootMargin: '0px', 
        threshold: 0.7,
      };
  
      
      const observer = new IntersectionObserver((entries) => {
      
        if (entries[0].isIntersecting) {

         setTimeout(() => {
          animateElement(); 
         }, 200);
         //animateElement(); 
          
          observer.disconnect();
        }
      }, options);
  
      
      if (animatedElementRef.current) {
        observer.observe(animatedElementRef.current);
      }
  
      return () => {
        if (animatedElementRef.current) {
          observer.unobserve(animatedElementRef.current);
        }
      };
    }, []);

    const animateElement = () => {
    
      if (animatedElementRef.current) {
         
         let leftBox = document.getElementById('leftbox');
         let leftCol = document.getElementById('targetCol');
         let rightBox = document.getElementById('rightBox');
         let rightCol = document.getElementById('rightCol');

        let screen = window.innerWidth;
       

        if(screen>890){

          if (screen > 1830) {
            leftBox.style.right = '16vw';
            leftCol.style.marginRight = '16vw';
            rightBox.style.left = '16vw';
            rightCol.style.marginLeft = '16vw';
         
        } else if (screen > 1630 && screen < 1830) {
            leftBox.style.right = '18vw';
            leftCol.style.marginRight = '18vw';
            rightBox.style.left = '18vw';
            rightCol.style.marginLeft = '18vw';
        } else if (screen > 478 && screen < 1630) {
            leftBox.style.right = '20vw';
            leftCol.style.marginRight = '20vw';
            rightBox.style.left = '20vw';
            rightCol.style.marginLeft = '20vw';
        }

    }
        

        
        
         
      }
    };
    
   /*-------------*/
  return (
    <>
   <div className='animated-element' ref={animatedElementRef}>
 <div className='cellphoneOuterWrapper'>
   <div className='montRegular titleText'>Te mostramos un ejemplo real para que 
      conozcas lo que vas a poder analizar!
   </div>
   <div className='cellphoneInnerWrapper'>
      <div id='targetCol' className='recsCol'>
         <div className='recRow mont400'>
            <img src={bottleIcon} className='bottleIcon'></img>
            <div>Recomendaciones <br/> de consumo</div>
         </div>
         <div className='recRow mont400'>
            <img src={manIcon} className='bottleIcon'></img>
            <div>Recomendaciones <br/> de ejercicio</div>
         </div>
      </div>
      <div className='cellphonesContainer'>

      
      <LeftCellphone></LeftCellphone>
      

      <div className='middleCellphone'>
         <img className='biosLogoIcon' src={biosLogoIcon}></img>
         <img className='biosName' src={biosName}></img>
      </div>

      {/*RIGHT CELLPHONE TEMP STYLES*/}
      
      <RightCellphone></RightCellphone>
      

      </div>
      <div id="rightCol" className='recsCol'>
      <div className='recRow mont400' style={{alignItems: 'flex-start', textAlign: 'left'}}>
         <img src={waveIcon} className='waveIcon'></img>
         <div>Recomendaciones <br/> de ejercicio</div>
      </div>
      <div className='recRow mont400' style={{alignItems: 'flex-start', textAlign: 'left'}}>
         <img src={appleIcon} className='appleIcon'></img>
         <div>Recomendaciones <br/> de ejercicio</div>
      </div>
    </div>
    
    <div className='recRowContainerMobile'>

         <div className='recRow montBold'>
            <img src={bottleIcon} className='bottleIcon'></img>
            <div className='montBold'>Recomendaciones <br/> de consumo</div>
         </div>

         <div className='recRow montBold'>
            <img src={manIcon} className='bottleIcon'></img>
            <div>Recomendaciones <br/> de ejercicio</div>
         </div>

         <div className='recRow mont400' >
           <img src={waveIcon} className='waveIcon'></img>
           <div>Recomendaciones <br/> de ejercicio</div>
        </div>

      <div className='recRow mont400' >
         <img src={appleIcon} className='appleIcon'></img>
         <div>Recomendaciones <br/> de ejercicio</div>
      </div>

    </div>

   </div>
</div>
<div className='sabeMasContainer'>
  <div className='descargarInformeText montBold'>Podes descargar un informe de ejemplo</div>
  <ButtonNB text={'Descargar informe'} link={"https://google.com"}></ButtonNB>
</div>
</div>
    
    </>
  )
}
