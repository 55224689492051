import React, { useEffect } from "react";
import { NavBar } from "../Home/NavBar/NavBar";

import './mobilemenu.css';

import {ReactComponent as MobileArrow} from '../assets/mobileArrow.svg';
import { Link } from "react-router-dom";

import {ReactComponent as FbIcon} from '../assets/imgs/footer/fbicon.svg';
import {ReactComponent as InstaIcon} from '../assets/imgs/footer/insta.svg';
import {ReactComponent as TwitterIcon} from '../assets/imgs/footer/twitter.svg';
import {ReactComponent as BubbleLogo} from '../assets/imgs/bubbleLogo.svg';

import almonds from '../assets/imgs/almonds.webp';
import waking_up from '../assets/imgs/waking_up.webp';
import leaf from '../assets/imgs/leaf.webp';
import salad from '../assets/imgs/salad.webp';

import { HashLink } from "react-router-hash-link";

import { useState } from "react";

function MobileMenu(props) {

    const [menu, setMenu] = useState(false);

    useEffect(() => {
        if(props.menu) {
            setMenu(true);
        } else {
            setMenu(false);
        }
    
    }, [props.menu])

    const callbackClose = () => {
        setMenu(false);
    }
    

    return (
        <>
        <div className="mobileMenuWrapper" style={menu ? {opacity: 1} : {opacity: 0, zIndex: -9999, position: 'absolute'}}>
            
            
                <NavBar change={true} onClick={props.onClick} callbackClose={callbackClose}></NavBar>
            
            <div className="coreMenuColumnWrapper">

                <div className="menuLinksColumn">
                    <HashLink to={'/quienes-somos#root'} onClick={() => {setMenu(false)}}>
                    <div className="menuLinkRow">
                        <div className="menuLinkRowTitle">
                            Sobre nosotros
                        </div>
                        <div className="menuLinkArrow">
                            <MobileArrow></MobileArrow>
                        </div>
                    </div>
                    </HashLink>
                    <HashLink to={'/quienes-somos#servicio'} onClick={() => {setMenu(false)}}>
                    <div className="menuLinkRow">
                        <div className="menuLinkRowTitle">
                            Cómo funciona
                        </div>
                        <div className="menuLinkArrow">
                            <MobileArrow></MobileArrow>
                        </div>
                    </div>
                    </HashLink>
                    <HashLink to={'/planes#root'} onClick={() => {setMenu(false)}}>
                    <div className="menuLinkRow">
                        <div className="menuLinkRowTitle">
                            Planes
                        </div>
                        <div className="menuLinkArrow">
                            <MobileArrow></MobileArrow>
                        </div>
                    </div>
                    </HashLink>
                    

                </div>

                <div className="redesAndMessageContainer">

                    <div className="redesLinks" style={props.plan ? {color: 'white'} : {}}>
                        <Link to={"https://facebook.com"}>
                            <FbIcon className="fbicon"></FbIcon>
                        </Link>
                        <Link to={"https://instagram.com"}>
                            <InstaIcon className="instaicon"></InstaIcon>
                        </Link>
                        <Link to={"https://twitter.com"}>
                            <TwitterIcon className="twittericon"></TwitterIcon>
                        </Link>
                    </div>

                    <div className="messageContainer">
                        Copyright © 2023 | BIOSTRACKER® . <br/> Todos los derechos reservados
                    </div>

                </div>

            </div>

        <div className="bubbleLogo">
            <BubbleLogo></BubbleLogo>
        </div>

        
        <img className="almondsPos" src={almonds}></img>
        

        
        <img className="leafPos" src={leaf}></img>
        

        <img className="saladPos" src={salad}></img>
        

        
        <img className="wakingUpPos" src={waking_up}></img>
        

        </div>

        
        
        </>
    )
}


export default MobileMenu;