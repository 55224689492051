import React from "react";

import { useState, useEffect } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";

function PurchaseForm (props) {

    //Form Data
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [floor, setFloor] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countries, setCountries] = useState([]);

    //Form dispatch
    const [enablePay, setEnablePay] = useState(false);

    const formData = {
        email: email,
        phone: phoneNumber,
        name: name,
        lastname: lastName,
        address: address,
        floor: floor,
        city: city,
        zipcode: zipcode,
        country: selectedCountry

    }

    //Form input error handling

    const [inputError, setInputError] = useState(null);



    //Fetch countries for select input

    useEffect(() => {
        // Fetch the JSON data
        fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
          .then(response => response.json())
          .then(data => {
            // Extract the countries array from the response
            const { countries } = data;
            setCountries(countries);
          })
          .catch(error => {
            console.error('Error fetching countries:', error);
          });
      }, []);


    const takeFormDataAndContinue = (event) => {
        //TODO: prevent form action default: 
        event.preventDefault();
        setEnablePay(true);
        console.log('call');
        //make all validations before submiting form data to parent
        props.setFormData(formData);

    }
    return (
        <>


    <form className="checkout-form" onSubmit={(event) => takeFormDataAndContinue(event)}>
    <label>Informacion de contacto</label>
    <input className="checkout-input" value={email} onChange={(event) => setEmail(event.target.value)} maxLength={100} type="email" placeholder="Email" required></input>
    <div style={window.innerWidth > 478 ? {maxWidth: '47.5%'} : {maxWidth: 'none'}}>
        <PhoneInputWithCountrySelect
            placeholder="Ingrese su telefono"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="AR"
        ></PhoneInputWithCountrySelect>
    </div>
    <label>Detalles de facturación y envio</label>
    <div style={{display: 'flex', gap: '5%'}}>
        <input className="checkout-input" value={name} onChange={(event) => setName(event.target.value)} maxLength={100} type="text" placeholder="Nombre" style={{width: '45%'}} required></input>
        <input className="checkout-input" value={lastName} onChange={(event) => setLastName(event.target.value)} maxLength={100} type="text" placeholder="Apellido" style={{width: '45%'}}required></input>
    </div>
    {/* Add HTML5 validation attributes for address fields */}
    <input className="checkout-input" placeholder="Dirección" value={address} onChange={(event) => setAddress(event.target.value)} required></input>
    <input className="checkout-input" placeholder="Piso (opcional)" value={floor} onChange={(event) => setFloor(event.target.value)}></input>
    <input className="checkout-input" placeholder="Ciudad" value={city} onChange={(event) => setCity(event.target.value)} required></input>
    <div style={{display: 'flex', gap: '5%'}}>
        <select className="checkout-input" id="countrySelect" value={selectedCountry} onChange={(event) => setSelectedCountry(event.target.value)} style={{maxWidth: '45%'}} required>
            <option value="">Seleccione un pais</option>
            {/* Map over the countries array and create an option for each country */}
            {countries.map(country => (
            <option key={country.value} value={country.value}>{country.label}</option>
            ))}
        </select>
        {/* Add HTML5 validation attributes for postal code field */}
        <input className="checkout-input" placeholder="Codigo postal" style={{width: '45%'}} value={zipcode} onChange={(event) => setZipcode(event.target.value)} required minLength={5}></input>
    </div>
    {/* No need for custom onClick event for form submission */}
    <button type="submit" className="buttonBlue">Continuar</button>
</form>

        </>
    )
}

export default PurchaseForm;