import React from "react";

import './membresia.css';
import '../../global.css';

import CornerLogo from '../../assets/imgs/membresia/cornerLogo.webp';
import {ReactComponent as BgImg} from '../../assets/imgs/membresia/aroExt.svg';

import {ReactComponent as InnerTitle} from '../../assets/imgs/membresia/innerTitle.svg';
import { ButtonNB } from "../../Components/ButtonNB";
import { useEffect, useState, useRef } from "react";

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import {ReactComponent as ArrowRight} from '../../assets/imgs/membresia/arrowRight.svg';
import {ReactComponent as ArrowLeft} from '../../assets/imgs/membresia/arrowLeft.svg';

function Membresia() {

    const [active, setActive] = useState(0);

    const splide = useRef(null);
    const [canGoPrev, setCanGoPrev] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    useEffect(()=>{
        let bubble1 = document.getElementById("bubble1");
        let bubble2 = document.getElementById("bubble2");
        bubble1.addEventListener("mouseover", () => {
            setActive(0);
        })
        bubble2.addEventListener("mouseover", () => {
            setActive(1);
        })
        
    }, [])

    useEffect(() => {
        if (splide.current) {
          const splideInstance = splide.current.splide;
          splideInstance.on('moved', () => {
            setCanGoPrev(splideInstance.index > 0);
            setCanGoNext(splideInstance.index < splideInstance.length - 1);
          });
        }
      }, []);

    //target.style.transform = "scale(0.7)";

    const activeStyle = {
        transform: 'scale(1)',
        'z-index': '1'
    }

    const inactiveStyle = {
        transform: 'scale(0.7)',
        'z-index': '0',
    }   


    return (
        <>
        

        <div className="membresiaWrapper">

            <div className="cornerLogoContainer hide">
                <img src={CornerLogo}></img>
            </div>
            <div className="titleContainer">
                <div className="titleTextMembresia montRegular">Elegí la membresía a medida <br/> para tus objetivos</div>
            </div>


            <div className="planesWrapper">
                
                <div id="bubble1" className="planContainerActive" style={active == 0 ? activeStyle : inactiveStyle}>

                    <div className="bgImageContainerMem" style={active == 0 ? {opacity: 1} : {opacity: 0}}>
                        <BgImg></BgImg>
                    </div>

                    <div className="innerPlanContainer">
                        <div className="innerTitleMem">
                            <InnerTitle></InnerTitle>
                        </div>
                        <div className="titleDivWrapper">
                            <div className="bigTextTitle mont400">
                                PLAN <br/> ANUAL
                            </div>
                            <div className="bottomLine"></div>
                        </div>

                        <div className="lowerTextWrapper">
                            <div className="lowerText">
                                4 extracciones <br/> Plan de acción personalizado <br/>
                                Contenido semanal digital
                            </div>
                            <ButtonNB link={"/planes"} hash={"top"} text={'Saber más'}></ButtonNB>
                        </div>

                    </div>
                </div>

                {/* */}
                <div id="bubble2" className="planContainerActive rightContainerBubble" style={active == 1 ? activeStyle : inactiveStyle}>

                    
                    <div className="bgImageContainerMem" style={active == 1 ? {opacity: 1} : {opacity: 0}}>
                        <BgImg></BgImg>
                    </div>

                    <div className="innerPlanContainer">
                        <div className="innerTitleMem">
                            <InnerTitle></InnerTitle>
                        </div>
                        <div className="titleDivWrapper">
                            <div className="bigTextTitle mont400">
                                PLAN <br/> SEMESTRAL
                            </div>
                            <div className="bottomLine"></div>
                        </div>

                        <div className="lowerTextWrapper">
                            <div className="lowerText">
                                2 extracciones <br/> Plan de acción personalizado <br/>
                                Contenido semanal digital
                            </div>
                            <ButtonNB link={"/planes"} text={'Saber más'} hash={"top"}></ButtonNB>
                        </div>

                    </div>
                </div>
                {/* */}
            </div>

            {/*mobile*/}
            <div className="sliderMemWrapper">

                <div  onClick={() => splide.current.go('-1')} className="arrowSliderContainer"
                style={canGoPrev ? {opacity: 1} : {opacity: 0}}>
                    <ArrowLeft></ArrowLeft>
                </div>

            <div className="mobileMembresiaSliderWrapper">

                <Splide ref={splide} options={{
                    autoWidth: true,
                    autoHeight: true,
                    width: '100%',
                    arrows: false,
                    pagination: false,
                    drag: false,
                    rewind: false,
                    type: 'fade',
                    speed: 1000
                }}>
                    
                    <SplideSlide>

                    <div id="bubble1" className="planContainerActive" style={active == 0 ? activeStyle : inactiveStyle}>

                        <div className="bgImageContainerMem" style={active == 0 ? {opacity: 1} : {opacity: 0}}>
                            <BgImg></BgImg>
                        </div>

                        <div className="innerPlanContainer">
                            <div className="innerTitleMem">
                                <InnerTitle></InnerTitle>
                            </div>
                            <div className="titleDivWrapper">
                                <div className="bigTextTitle mont400">
                                    PLAN <br/> ANUAL
                                </div>
                                <div className="bottomLine"></div>
                            </div>

                            <div className="lowerTextWrapper">
                                <div className="lowerText">
                                    4 extracciones <br/> Plan de acción personalizado <br/>
                                    Contenido semanal digital
                                </div>
                                <ButtonNB link={"/quienes-somos"} text={'Saber más'}></ButtonNB>
                            </div>

                            </div>
                        </div>       

                    </SplideSlide>
                    <SplideSlide>

                        <div id="bubble1" className="planContainerActive" style={active == 0 ? activeStyle : inactiveStyle}>

                            <div className="bgImageContainerMem" style={active == 0 ? {opacity: 1} : {opacity: 0}}>
                                <BgImg></BgImg>
                            </div>

                            <div className="innerPlanContainer">
                                <div className="innerTitleMem">
                                    <InnerTitle></InnerTitle>
                                </div>
                                <div className="titleDivWrapper">
                                    <div className="bigTextTitle mont400">
                                        PLAN <br/> SEMESTRAL
                                    </div>
                                    <div className="bottomLine"></div>
                                </div>

                                <div className="lowerTextWrapper">
                                    <div className="lowerText">
                                        2 extracciones <br/> Plan de acción personalizado <br/>
                                        Contenido semanal digital
                                    </div>
                                    <ButtonNB link={"/quienes-somos"} text={'Saber más'}></ButtonNB>
                                </div>

                                </div>
                            </div>       

                    </SplideSlide>
                    
              
                </Splide>
                
                

            </div>

            <div  onClick={() => splide.current.go('+1')} className="arrowSliderContainer"
            style={canGoNext ? {opacity: 1} : {opacity: 0}}>
                    <ArrowRight></ArrowRight>
                </div>

            </div>


        </div>


        </>
    )
}

export default Membresia;